import { IAdminState } from "./admin.interface";

export const adminInitialState: IAdminState = {
  _id: null,
  isFetching: false,
  message: "",
  errorTitle: "",
  info: {
    _id: "",
    name: "",
    email: "",
    status: "active",
    phoneNumber: "",
    createdAt: "",
    updatedAt: "",
    avatar: "",
    isActive: false,
  },
  adminList: [],
  total: 0,
  detail: {},
  form: {
    admin_id: "",
    email: "",
    name: "",
    phoneNumber: "",
    status: "active",
    errors: {},
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    password: "",
    avatar: "",
  },
  query: {
    page: 1,
    pageSize: 10,
    status: "",
    sort: "",
  },
  changepassVisible: false
};
