import {IOpenInterestState} from "./open-interest.interface";

const state: IOpenInterestState = {
  isFetching: false,
  message: "",
  openInterestData: {},
  memeData: [],
  query: {
    created_from: 0,
    created_to: 0,
    symbols: "",
  },
};
export default state;
