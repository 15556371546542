import {createAction} from "../common";
import openInterestApi from "./open-interest.api";
import {
  IOpenInterestQuery,
  IOpenInterestState,
} from "./open-interest.interface";
import utils from "../../modules/utils";
import {binanceApi} from "../../modules/binance-api";

export const Types = {
  setState: "openInterest.setState",
  cleanAll: "openInterest.cleanAll",
  resetState: "openInterest.resetState",

  getOpenInterestsRequest: "openInterest.get-list.request",
  getOpenInterestsSuccess: "openInterest.get-list.success",
  getOpenInterestsFailure: "openInterest.get-list.failure",

  getOpenInterestsMemeRequest: "openInterest.get-list-meme.request",
  getOpenInterestsMemeSuccess: "openInterest.get-list-meme.success",
  getOpenInterestsMemeFailure: "openInterest.get-list-meme.failure",
};

const setState = (state: Record<string, any>) =>
  createAction(Types.setState, {state});
const resetState = (stateName: keyof IOpenInterestState) =>
  createAction(Types.resetState, {stateName});
const cleanAll = () => createAction(Types.cleanAll);

const getList = (query: IOpenInterestQuery, key: string) => (dispatch) => {
  dispatch(createAction(Types.getOpenInterestsRequest));
  return openInterestApi.getList(query).then((res) => {
    if (res.success) {
      dispatch(
        createAction(Types.getOpenInterestsSuccess, {
          data: res.data,
          key,
          // total: res.total,
        })
      );
      return true;
    } else {
      const message = utils.getMessageError(res.errors);
      dispatch(createAction(Types.getOpenInterestsFailure, {}, message));
      return false;
    }
  });
};
const getMemeList = (query: Partial<IOpenInterestQuery>) => async (
  dispatch
) => {
  dispatch(createAction(Types.getOpenInterestsMemeRequest));
  const symbols =
    "1000FLOKIUSDT MEMEUSDT 1000PEPEUSDT 1000XECUSDT 1000SATSUSDT 1000RATSUSDT 1000SHIBUSDT DOGEUSDT BOMEUSDT WIFUSDT";
  const stickerRes = await binanceApi.ticker24h(symbols);
  const stickers = stickerRes.data;
  return openInterestApi
    .getList({
      ...query,
      symbols,
    })
    .then((res) => {
      if (res.success) {
        dispatch(
          createAction(Types.getOpenInterestsMemeSuccess, {
            data: res.data,
            stickers,
          })
        );
        return true;
      } else {
        const message = utils.getMessageError(res.errors);
        dispatch(createAction(Types.getOpenInterestsMemeFailure, {}, message));
        return false;
      }
    });
};

export const openInterestActions = {
  setState,
  resetState,
  cleanAll,
  getList,
  getMemeList,
};
