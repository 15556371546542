import AppApi from "../../modules/api";
import { IUploadFile } from "./file.interface";

export const fileApi = {
  upload: (params: IUploadFile, fileList: any[]) => {
    const body = new FormData();
    fileList.forEach((file) => {
      body.append("files", file);
    });
    const queryString = AppApi.formatQuery(params);
    const api = "/api/file/admin/upload" + queryString;
    return AppApi.fetchData(api, "POST", body);
  },
};
