import AppApi from "../../modules/api";
import { ISettingQuery, ISettingUpdate } from "./setting.interface";

export const settingApi = {
  getList: (query: ISettingQuery) => {
    const queryString = AppApi.formatQuery(query);
    const api = "/api/setting/admin/get-list" + queryString;
    return AppApi.fetchData(api);
  },
  update: <T>(doc: ISettingUpdate<T>) => {
    const api = "/api/setting/admin/update";
    return AppApi.fetchData(api, "PUT", JSON.stringify(doc));
  },
};
