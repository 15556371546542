import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IRootState } from "../../redux";
import { fileAction } from "../../redux/file";
import { Upload } from "antd";
import { FaPlus } from "react-icons/fa";
import { UploadFile } from "antd/lib/upload/interface";
import utils from "../../modules/utils";
import Modal from "antd/lib/modal/Modal";
import _ from "lodash";

export interface IFile {
  name: string;
  type?: string;
  size?: number;
  uid: string;
  url?: string;
  status: string;
  originFileObject?: IFile;
}
interface IUpload {
  urlList: string[];
  customRef?: any;
  onChange?: (fileList: UploadFile[]) => void;
  max?: number;
  type?: "avatar";
}

const mapStateToProps = (state: IRootState) => ({
  isFetching: state.file.isFetching,
});
const mapDispatchToProps = {
  upload: fileAction.upload,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ImageScreen = React.memo((props: PropsFromRedux & IUpload) => {
  const { urlList, max, type, onChange } = props;
  const [fileList, setFileList] = useState<Array<UploadFile>>([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  useEffect(() => {
    if (urlList.length) {
      const rawFileList: any[] = [];
      console.log("urllist", urlList);
      urlList.forEach((item, index) => {
        const prefix = item.substr(item.lastIndexOf("."), item.length);
        const name = index + prefix;
        const file = {
          uid: index.toString(),
          name,
          status: "done",
          url: utils.getFileUrl(item),
        };
        rawFileList.push(file);
      });
      setFileList([...rawFileList]);
    }
  }, [urlList]);

  const onRequest = async (options: any) => {};

  const onFileChange = async ({ fileList: imageList }) => {
    console.log("image list", imageList);
    const convertList: UploadFile[] = [];
    for (const item of imageList) {
      const originFileObj = item.originFileObj;
      if (originFileObj) {
        console.log("originalfile object", item, originFileObj);
        convertList.push({
          ...originFileObj,
          originFileObj,
          status: "done",
          url:
            item.url && item.url.includes("http")
              ? item.url
              : await utils.getBase64(originFileObj),
        });
      } else {
        convertList.push({
          ...item,
          url: item.url && item.url.includes("http") ? item.url : "",
        });
      }
    }
    const newList: UploadFile[] = [...convertList];
    console.log("new list", newList);
    setFileList(newList);
    if (onChange) {
      onChange(newList);
    }
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await utils.getBase64(file.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);
  };
  const handleCancel = () => {
    setPreviewImage("");
    setPreviewVisible(false);
  };

  console.log("file list", fileList);
  return (
    <div>
      <Upload
        // onChange={onSelectFile}
        customRequest={onRequest}
        onPreview={handlePreview}
        fileList={_.uniqBy(fileList, "uid")}
        listType="picture-card"
        // onRemove={onRemoveImage}
        multiple={true}
        onChange={onFileChange}
        maxCount={max}
        accept={".png, .jpg, .jpeg"}
      >
        {max && fileList.length < max && (
          <div>
            {" "}
            <FaPlus />
            {type === "avatar" && <p className="py-0">Cập nhật avatar</p>}
          </div>
        )}
      </Upload>
      <Modal visible={previewVisible} onCancel={handleCancel}>
        <img alt="preview" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </div>
  );
});

export const ImageUpload = connector(ImageScreen);
// const getUniqueFileList = (fileList: UploadFile[]) => {
//   const uniqueList: UploadFile[] = [];
//   fileList.forEach(item => {
//     _.
//   })
// }
