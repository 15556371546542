export default {
  BACKEND_HOST: 'https://api.sieuthihoantien.com',
  IMAGE_HOST: 'https://api.sieuthihoantien.com',
  VERSION: '1.0.0',
  DEFAULT_LANGUAGE: 'vi',
  SHARE_HOST: 'https://myvietnam.app:3002',
  VIDEOS_PER_PAGE: 10,
  MAX_RELATED_VIDEOS: 10,
  ITEM_PER_PAGE: 10,
  PLACE_HOST: 'https://thongtindoanhnghiep.com',
  GOOGLE_MAP_API_KEY: 'AIzaSyDeXZS9EdkVHlEBz8nL428p1fNrXppk_Qw',
};
