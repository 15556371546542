import { IRootState } from "..";
import utils from "../../modules/utils";
import { createAction } from "../common";
import { settingApi } from "./setting.api";
import { ISettingQuery, ISettingUpdate } from "./setting.interface";

export const Types = {
  setInfo: "setting.set-user",
  setState: "setting.set-state",
  cleanAll: "setting.clean-all",
  mapDataToForm: "setting.map-data-to-form",
  cleanState: "setting.clean-state",

  getSettingListRequest: "setting.get-list.request",
  getSettingListSuccess: "setting.get-list.success",
  getSettingListFailure: "setting.get-list.failure",

  updateSettingRequest: "setting.update.request",
  updateSettingSuccess: "setting.update.success",
  updateSettingFailure: "setting.update.failure",
};
const setInfo = (data: any = {}) => createAction(Types.setInfo, data);
const setState = (state: any = {}) => createAction(Types.setState, { state });
const cleanAll = () => createAction(Types.cleanAll);
const mapDataToForm = (data: any) =>
  createAction(Types.mapDataToForm, { data });
const cleanState = (stateName: string) =>
  createAction(Types.cleanState, { stateName });

const getList = (query: ISettingQuery) => (dispatch, getState) => {
  const state: IRootState = getState();
  if (state.setting.isGotList) {
    return Promise.resolve(true);
  }
  dispatch(createAction(Types.getSettingListRequest));
  return settingApi.getList(query).then((res) => {
    if (res.success) {
      dispatch(createAction(Types.getSettingListSuccess, { data: res.data }));
      return true;
    } else {
      const message = utils.getMessageError(res.errors);
      dispatch(createAction(Types.getSettingListFailure, {}, message));
      return false;
    }
  });
};
const update = <T>(doc: ISettingUpdate<T>) => (dispatch) => {
  dispatch(createAction(Types.updateSettingRequest));
  return settingApi.update(doc).then((res) => {
    if (res.success) {
      dispatch(createAction(Types.updateSettingSuccess, { data: res.data }));
      return true;
    } else {
      const message = utils.getMessageError(res.errors);
      dispatch(createAction(Types.updateSettingFailure, {}, message));
      return false;
    }
  });
};

export const settingAction = {
  setInfo,
  setState,
  cleanAll,
  getList,
  update,
  mapDataToForm,
  cleanState,
};
