import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IRootState } from "../../redux";
import { TableCustom } from "../_libCustom/TableCustom";
import { ColumnsType } from "antd/lib/table";
import { Button, Col, Input, Row, Switch, Tooltip } from "antd";
import { FaEdit } from "react-icons/fa";
import { symbolActions } from "../../redux/symbol";

const mapStateToProps = (state: IRootState) => ({
  symbol: state.symbol,
});
const mapDispatchToProps = {
  getSymbolList: symbolActions.getList,
  createSymbol: symbolActions.create,
  updateSymbol: symbolActions.update,
  setSymbolState: symbolActions.setState,
  getAllSymbol: symbolActions.getAll
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const LoginScreen = React.memo((props: PropsFromRedux) => {
  const {
    symbol,
    getSymbolList,
    updateSymbol,
    setSymbolState,
    getAllSymbol
    // updateAdmin,
  } = props;
  const { status } = symbol.query;
  useEffect(() => {
    getSymbolList({
      status,
    }).then(data => {
      setTimeout(()=>{
        getAllSymbol();
      }, 500)
    });
  }, [status, getSymbolList, getAllSymbol]);

  const onChangeStatus = (id: string, status: string) => {
    updateSymbol(id, { status });
  };

  const columns: ColumnsType<any> = [
    {
      title: "#",
      key: "key",
      dataIndex: "key",
      render: (value, record, index) => ++index,
      width: "50px",
    },
    {
      title: "Cặp",
      key: "symbol",
      dataIndex: "symbol",
      width: "100px",
    },
    {
      title: "",
      key: "action",
      dataIndex: "action",
      render: (action, record, index) => {
        return (
          <div style={{ textAlign: "center" }}>
            <Tooltip
              title={
                record.status === "active"
                  ? "Click để vô hiệu"
                  : "Click để kích hoạt"
              }
            >
              <Switch
                onChange={(checked) =>
                  onChangeStatus(record._id, checked ? "active" : "inactive")
                }
                checked={record.status === "active"}
                style={{ marginRight: "3px" }}
              />
            </Tooltip>
            <Tooltip title="Click để chỉnh sửa">
              <Button
                onClick={() => {
                  // direct(routeName.notificationPush + "?id=" + record._id);
                  setSymbolState({
                    modalVisible: true,
                    "form.symbol": record.symbol,
                    "form._id": record._id,
                  });
                }}
                className="text-center"
                type="primary"
              >
                <FaEdit />
              </Button>
            </Tooltip>
          </div>
        );
      },
      width: "100px",
    },
  ];

  return (
    <div className="table-custom">
      <TableCustom
        columns={columns}
        dataSource={symbol.search ? symbol.symbolList.filter(item => item.symbol.includes(symbol.search?.toUpperCase())): symbol.symbolList}
        pagination={{ total: symbol.symbolList.length, defaultPageSize: 100 }}
        // onChange={onTableChange}
        scroll={{ x: "768px" }}
        loading={symbol.isFetching}
        title={() => (
          <Row>
            <Col span={8}>
              <span className="card-custom-title" style={{ color: "#000" }}>
                Danh sách SYMBOL
              </span>
            </Col>

            <Col span={8}>
              <Input value={symbol.search} onChange={(e)=> setSymbolState({search: e.target.value})} />
            </Col>
            <Col span={8} style={{ display: "flex", justifyContent: "right" }}>
              <Button
                onClick={() => setSymbolState({ modalVisible: true })}
                type="primary"
              >
                Thêm mới
              </Button>
            </Col>
          </Row>
        )}
      />
    </div>
  );
});

export const NotificationList = connector(LoginScreen);
