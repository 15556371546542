import produce from "immer";
import _ from "lodash";

import { Action, commonHandler } from "../common";
import { Types } from "./setting.action";
import { settingInitialState } from "./setting.initial-state";
import { ISetting, ISettingState } from "./setting.interface";

const rawUserReducer = (state: ISettingState, action: Action) => {
  state = commonHandler(state, action, settingInitialState);
  const data = action.data;
  const message = action.message;
  switch (action.type) {
    case Types.setState: {
      for (const key in data.state) {
        _.set(state, key, data.state[key]);
      }
      return state;
    }
    case Types.cleanAll: {
      return { ...settingInitialState };
    }

    case Types.mapDataToForm: {
      for (const key in data.data) {
        if (key === "_id") {
          _.set(state, "form.setting_id", data.data[key]);
        } else {
          _.set(state, "form." + key, data.data[key]);
        }
      }
      return state;
    }

    case Types.cleanState: {
      _.set(state, data.stateName, settingInitialState[data.stateName]);
      return state;
    }

    case Types.getSettingListRequest: {
      _.set(state, "isFetching", true);
      _.set(state, "message", "");
      return state;
    }
    case Types.getSettingListSuccess: {
      const dataList: ISetting<any>[] = data.data;
      dataList.forEach((item) => {
        _.set(state, item.key, item);
      });
      _.set(state, "isFetching", false);
      _.set(state, "isGotList", true);
      return state;
      // _.set(state)
    }
    case Types.getSettingListFailure: {
      _.set(state, "isFetching", false);
      _.set(state, "message", message);
      _.set(state, "errorTitle", "Lấy danh sách cài đặt thất bại");
      return state;
    }

    case Types.updateSettingRequest: {
      _.set(state, "isFetching", true);
      _.set(state, "message", "");
      return state;
    }
    case Types.updateSettingSuccess: {
      if (data.data) {
        _.set(state, data.data.key, data.data);
      }
      _.set(state, "isFetching", false);
      return state;
      // _.set(state)
    }
    case Types.updateSettingFailure: {
      _.set(state, "isFetching", false);
      _.set(state, "message", message);
      _.set(state, "errorTitle", "Cập nhật thất bại");
      return state;
    }

    default:
      return state;
  }
};

export const settingReducer = produce(rawUserReducer, settingInitialState);
