import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { IRootState } from "../../redux";
import {
  Form,
  Input,
  Button,
  Spin,
  Modal,
} from "antd";
import { symbolActions } from "../../redux/symbol";

const mapStateToProps = (state: IRootState) => ({
  symbol: state.symbol,
});
const mapDispatchToProps = {
  setSymbolState: symbolActions.setState,
  createSymbol: symbolActions.create,
  updateSymbol: symbolActions.update,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const CreateOrEdit = React.memo((props: PropsFromRedux) => {
  const { symbol, setSymbolState, createSymbol, updateSymbol } = props;
  const symbolList = symbol.form.symbol ? symbol.allSymbolList.filter(item => item.includes(symbol.form.symbol)) : [];
  const onCancel = () => {
    setSymbolState({ "form.symbol": "", modalVisible: false, message: '' });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if(symbol.symbolList.find(item => item.symbol === symbol.form.symbol)){
      setSymbolState({message: 'Symbol đã tồn tại'});
      return;
    }
    if(!symbol.allSymbolList.some(item => item === symbol.form.symbol)){
      setSymbolState({message: 'Symbol không đúng'});
      return;
    }
    if (symbol.form._id) {
      updateSymbol(symbol.form._id, { symbol: symbol.form.symbol }).then(
        (result) => {
          if (result) {
            onCancel();
          }
        }
      );
    } else {
      createSymbol({ symbol: symbol.form.symbol }).then((result) => {
        onCancel();
      });
    }
  };

  return (
    <Spin spinning={symbol.isFetching}>
      <Modal visible={symbol.modalVisible} onCancel={onCancel} footer={null}>
        <Form>
          <Input
            placeholder="Nhập tên Symbol"
            value={symbol.form.symbol}
            onChange={(e) => setSymbolState({ "form.symbol": e.target.value.toUpperCase(), message:'' })}
          />
          {symbol.message && <p style={{color:'#ca0307'}}>{symbol.message}</p>}
          <p style={{maxHeight:'200px', overflow:'auto'}}>{symbolList.join(' - ')}</p>
          <div style={{display:'flex'}}>
          <Button onClick={onSubmit} style={{margin:'auto'}} htmlType="submit">submit</Button>
          </div>
        </Form>
      </Modal>
    </Spin>
  );
});

export const CreateOrEditSymbol = connector(CreateOrEdit);
