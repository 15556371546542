import React, { useEffect } from "react";
import _ from "lodash";
import { Form, Input, Button, Spin } from "antd";
import { connect, ConnectedProps } from "react-redux";
import { push } from "connected-react-router";
import { routeName } from "../../config/route-name";
import notification from "../../modules/notification";
import { adminAction } from "../../redux/admin";
import authAction from "../../redux/auth/auth.action";
import { IRootState } from "../../redux";
import { getJoiError, validate } from "../../modules";
import { authValidation } from "../../redux/auth";

const mapStateToProps = (state: IRootState) => ({
  email: state.auth.email,
  password: state.auth.password,
  errors: state.auth.errors,
  isFetching: state.auth.isFetching,
  message: state.auth.message,
});
const mapDispatchToProps = {
  changeFields: adminAction.changeFields,
  goToPage: push,
  login: authAction.login,
  setAuthState: authAction.setState,
  resetPassword: authAction.resetPassword,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const LoginScreen = React.memo((props: PropsFromRedux) => {
  const {
    email,
    message,
    isFetching,
    errors,
    setAuthState,
    resetPassword,
    goToPage,
  } = props;

  useEffect(() => {
    if (message) {
      notification.showError(message);
      setAuthState({ message: "" });
    }
  }, [message, setAuthState]);

  const handleSubmit = () => {
    // e.preventDefault();
    let newErrors = { ...errors };
    const data: any = { email };
    const { error } = validate(data, authValidation.resetPassword);
    if (error) {
      const joiError = getJoiError(error);
      newErrors = { ...newErrors, ...joiError };
    }
    if (_.isEmpty(newErrors)) {
      resetPassword(email).then((success) => {
        if (success) {
          notification.showSuccess(
            "Reset mật khẩu thành công",
            "Reset mật khẩu thành công, mời bạn check mail để nhận mật khẩu mới"
          );
          setAuthState({ email: "" });
          goToPage(routeName.login);
        }
      });
    } else {
      setAuthState({ errors: { ...newErrors } });
    }
  };

  const onInputChange = (key, value) => {
    if (errors[key]) {
      delete errors[key];
    }
    setAuthState({ [key]: value, errors: { ...errors } });
  };

  return (
    <Spin spinning={isFetching }>
      <Form onFinish={handleSubmit} className="login-form">
        <Form.Item>
          <Input
            // prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
            name="email"
            onChange={(event) => onInputChange("email", event.target.value)}
            placeholder="Nhập email để nhận mật khẩu"
            value={email}
          />
          {errors.email && <span style={{ color: "red" }}>{errors.email}</span>}
        </Form.Item>
        <div style={{ textAlign: "center" }}>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Gửi
          </Button>
          <div style={{ textAlign: "left" }}>
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => props.goToPage(routeName.login)}
            >
              <i>Quay lại login</i>
            </span>
          </div>
        </div>
      </Form>
    </Spin>
  );
});

export const ForgotPassword = connector(LoginScreen);
