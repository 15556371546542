export interface IGetKline {
  symbol: string;
  interval: string;
  startTime?: number;
  endTime?: number;
  limit?: number;
}

class BinanceApi {
  baseUrl = "https://api3.binance.com";
  baseFutureUrl = "https://fapi.binance.com";
  private formatQuery(query: any) {
    let queryString = "?";
    for (const key in query) {
      if (!query[key]) delete query[key];
      else if (typeof query[key] != "undefined") {
        if (query[key] instanceof Array) {
          if (!query[key].length) {
            delete query[key];
          } else {
            // eslint-disable-next-line no-loop-func
            query[key].forEach((item, index) => {
              queryString += `${key}[${index}]=${item}&`;
            });
            delete query[key];
          }
          continue;
        }
        queryString += `${key}=${query[key]}&`;
      }
    }
    return queryString;
  }

  private async request(url: string, options?: RequestInit) {
    try {
      const response = await fetch(url, options);
      const json = await response.json();
      console.log("json", json);
      return {data: json, success: response.ok, message: ""};
    } catch (err) {
      console.log("error", err);
      return {success: false, message: err as string};
    }
  }
  getKline(params: IGetKline) {
    const endpoint = this.baseUrl + "/api/v3/klines" + this.formatQuery(params);
    return this.request(endpoint, {method: "get"});
  }
  ticker24h(symbols: string) {
    const endpoint =
      this.baseFutureUrl +
      `/fapi/v2/ticker/price?symbols=${JSON.stringify(symbols.split(" "))}`;
    return this.request(endpoint, {method: "get"});
  }
}

export const binanceApi = new BinanceApi();
