
  import { ISymbolState } from "./symbol.interface";
  
  const state: ISymbolState = {
    isFetching: false,
    message: "",
    search: '',
    symbolList: [],
    allSymbolList: [],
    query: {
      status: ''
    },
    form: {symbol: '', _id: ''},
    modalVisible: false
  };
  export default state;
  
  