import { ISettingState } from "./setting.interface";

export const settingInitialState: ISettingState = {
  isFetching: false,
  message: "",
  errorTitle: "",
  isGotList: false,
  age: {
    _id: "",
    key: "age",
    valueList: [],
  },
  duration: {
    _id: "",
    key: "duration",
    valueList: [],
  },
  gender: {
    _id: "",
    key: "gender",
    valueList: [],
  },
  startTime: {
    _id: "",
    key: "startTime",
    valueList: [],
  },
  registerNotification: {
    _id: "",
    key: "registerNotification",
    valueList: [],
    title: "",
    content: "",
  },
  remindNotification: {
    _id: "",
    key: "remindNotification",
    valueList: [],
    title: "",
    content: "",
  },
  form: {
    setting_id: "",
    errors: {},
  },
  editingKey: "",
};
