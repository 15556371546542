import { IGetKline, binanceApi } from "../../modules/binance-api";
import { createAction } from "../common";
import { exchangeApi } from "./exchange.api";
import { IExchangeState, IGetExchange } from "./exchange.interface";

export const Types = {
  setState: "exchange.setState",
  cleanAll: "exchange.cleanAll",
  resetState: "exchange.resetState",

  getExchangesRequest: "exchange.get-exchanges.request",
  getExchangesSuccess: "exchange.get-exchanges.success",
  getExchangesFailure: "exchange.get-exchanges.failure",

  getBtcPricesRequest: "exchange.get-BtcPrices.request",
  getBtcPricesSuccess: "exchange.get-BtcPrices.success",
  getBtcPricesFailure: "exchange.get-BtcPrices.failure",
};

const setState = (state: Record<string, any>) =>
  createAction(Types.setState, { state });
const resetState = (stateName: keyof IExchangeState) =>
  createAction(Types.resetState, { stateName });
const cleanAll = () => createAction(Types.cleanAll);

const getExchanges = (query: IGetExchange) => (dispatch) => {
  dispatch(createAction(Types.getExchangesRequest));
  return exchangeApi.getExchanges(query).then((res) => {
    if (res.success) {
      dispatch(
        createAction(Types.getExchangesSuccess, {
          data: res.data,
          metaData: res.metaData,
        })
      );
      return res;
    } else {
      dispatch(createAction(Types.getExchangesFailure, {}, res.message));
      return false;
    }
  });
};

const getBtcPrices = (query: IGetKline) => (dispatch) => {
  dispatch(createAction(Types.getBtcPricesRequest));
  return binanceApi.getKline(query).then((res) => {
    if (res.success) {
      dispatch(
        createAction(Types.getBtcPricesSuccess, {
          data: res.data,
        })
      );
      return true;
    } else {
      dispatch(createAction(Types.getBtcPricesFailure, {}, res.message));
      return false;
    }
  });
};

export const exchangeActions = {
  setState,
  resetState,
  cleanAll,
  getExchanges,
  getBtcPrices,
};
