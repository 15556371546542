import React, {useEffect} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IRootState} from "../../redux";
import {TimeSelector} from "../../components/_libCustom";
import {exchangeActions} from "../../redux/exchange";
import {MulLineChart} from "../../components/_libCustom/MulLineChart";
import {ONE_HOUR_IN_MIL_SECONDS} from "../../modules/times";
import {openInterestActions} from "../../redux/open-interest";

const mapStateToProps = (state: IRootState) => ({
  exchangeData: state.exchange.data,
  exchangeLabels: state.exchange.labels,
  symbolPrices: state.exchange.symbolPrices,
  query: state.exchange.query,
  fetching: state.exchange.isFetching,
  openInterest: state.openInterest.memeData
});

const mapDispatchToProps = {
  getExchanges: exchangeActions.getExchanges,
  setExchangeState: exchangeActions.setState,
  getBtcPrices: exchangeActions.getBtcPrices,
  getMemeOpenInterest: openInterestActions.getMemeList,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const timeSelectorList = [
  {key: "1h", value: 1},
  {key: "2h", value: 2},
  {key: "4h", value: 4},
  {key: "6h", value: 6},
  {key: "12h", value: 12},
  {key: "24h", value: 24},
  {key: "2d", value: 48},
  {key: "7d", value: 24 * 7},
  {key: "30d", value: 24 * 30},
  {key: "60d", value: 24 * 30 * 2},
  {key: "90d", value: 24 * 30 * 3},
  {key: "All", value: 24 * 30 * 3.5},
];

const periodTimeToStep = (createdFrom: number, createdTo: number) => {
  if (createdFrom && createdTo) {
    const hourPeriod = Math.floor(
      (createdTo - createdFrom) / ONE_HOUR_IN_MIL_SECONDS
    );
    if (hourPeriod <= 24) {
      return "5m";
    }
    if (hourPeriod <= 24 * 7) {
      return "1h";
    }
    if (hourPeriod <= 24 * 30) {
      return "12h";
    }
  }
  // fix this after 03/12 (14 days after)
  return "1d";
};

const AdminScreen = React.memo((props: PropsFromRedux) => {
  const {
    getExchanges,
    setExchangeState,
    getBtcPrices,
    getMemeOpenInterest,
    exchangeData,
    exchangeLabels,
    symbolPrices,
    query,
    openInterest
  } = props;
  const {created_from, created_to, timeSelector} = query;
  useEffect(() => {
    getExchanges({created_from, created_to}).then((response) => {
      const metaData = response.metaData;
      const interval = periodTimeToStep(created_from, created_to);
      getBtcPrices({
        symbol: "BTCUSDT",
        interval,
        endTime: created_to,
        limit: metaData.total,
        // startTime: created_from,
      });
    });
    
    getMemeOpenInterest({created_from, created_to});
  }, [
    created_from,
    created_to,
    getExchanges,
    getBtcPrices,
    getMemeOpenInterest,
  ]);

  const onTimeSelectorChange = (item: {key: string; value: number}) => {
    const created_from =
      item.key === "All"
        ? undefined
        : created_to - item.value * ONE_HOUR_IN_MIL_SECONDS;
    setExchangeState({
      "query.timeSelector": item.key,
      "query.created_from": created_from,
    });
  };
  return (
    <div>
      <TimeSelector
        timeList={timeSelectorList}
        value={timeSelector}
        onChange={onTimeSelectorChange}
      />
      <MulLineChart
        data={{
          Spot: exchangeData.spot24hVol,
          Future: exchangeData.future24hVol,
          "Open Interest": exchangeData.futureInterest24hVol,
          BTCUSDT: symbolPrices.btc,
          MemeOP: openInterest
        }}
        labels={exchangeLabels}
        title="Volume"
      />

      <div className="mt-3"></div>

      <MulLineChart
        data={{
          "Fu/Spot": exchangeData.futurePerSpot,
          "OP/Spot": exchangeData.opPerSpot,
        }}
        labels={exchangeLabels}
        title="Volume Scale"
      />
    </div>
  );
});

export const Exchange = connector(AdminScreen);
