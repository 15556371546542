import config from "../config/setting";
import language from "./language";

const fbImageType = "normal";
// let youtubeImageType = 'hqdefault';

function getFacebookAvatar(fbId: string) {
  return `http://graph.facebook.com/${fbId}/picture?type=${fbImageType}`;
}

function getAvatarOfUser({ avatar, fb_id }: { avatar: string; fb_id: string }) {
  if (avatar) {
    if (!avatar.includes("http")) {
      avatar = config.IMAGE_HOST + "/" + avatar;
    }
    return avatar;
  } else if (fb_id) {
    return getFacebookAvatar(fb_id);
  }

  return null;
}

function shuffle(array: any) {
  if (!array) {
    return [];
  }
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

const convertTimeRemainToString = (timeRemain: any, format: string) => {
  const miliSecondsPerHour = 60 * 60 * 1000;
  const miliSecondsPerDay = miliSecondsPerHour * 24;
  const times = new Date(timeRemain).getTime();
  const day = Math.floor(times / miliSecondsPerDay);
  const hour = Math.floor((times % miliSecondsPerDay) / miliSecondsPerHour);
  const min = Math.floor((times % miliSecondsPerHour) / 60000);
  return format
    .replace("DD", day.toString())
    .replace("dd", day.toString())
    .replace("HH", hour.toString())
    .replace("hh", hour.toString())
    .replace("MM", min.toString())
    .replace("mm", min.toString());
};

const convertTimeToString = (time: any, format: string) => {
  const date = new Date(time);
  const year = date.getFullYear();
  const mon = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  const hour = ("0" + date.getHours()).slice(-2);
  const min = ("0" + date.getMinutes()).slice(-2);
  const second = ("0" + date.getSeconds()).slice(-2);

  return format
    .replace("YYYY", year.toString())
    .replace("yyyy", year.toString())
    .replace("dd", day.toString())
    .replace("DD", day.toString())
    .replace("MM", mon.toString())
    .replace("hh", hour.toString())
    .replace("mm", min.toString())
    .replace("ss", second.toString());
};

const upperCaseFirstLetter = (string: string) => {
  if (!string || string.length === 0) {
    return string;
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
};

function getHumanReadableNumber(number: number, fixed = 2) {
  if (!number) {
    return 0;
  }
  const postfix = ["K", "M", "B", "T"];
  const arrange = [1000, 1000000, 1000000000, 1000000000000];
  let numberString = "";
  for (let i = arrange.length - 1; i >= 0; i--) {
    if (number > arrange[i]) {
      numberString = (number / arrange[i]).toFixed(fixed) + postfix[i];
      break;
    }
  }

  return numberString;
}

function numberWithCommas(x: number) {
  if (typeof x === "undefined") {
    return 0;
  }
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

function vndFormat(x: number) {
  if (typeof x !== "number") return;
  return x.toLocaleString("vi-VN", { style: "currency", currency: "VND" });
}

function getMessageError(errors: any) {
  for (const key in errors) {
    return language.t(errors[key]);
  }
}
function getFileUrl(path: string) {
  const fileHost: string = process.env.REACT_APP_FILE_HOST || "";
  if (!path) {
    return path;
  }

  if (
    path.includes(fileHost) ||
    path.includes("http://") ||
    path.includes("https://")
  ) {
    return path;
  }

  return fileHost + path;
}

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const millisecondsToTime = (duration: number) => {
  let [day, hour, minute, seconds] = [0, 0, 0, 0];
  seconds = Math.floor(duration / 1000);
  minute = Math.floor(seconds / 60);
  seconds = seconds % 60;
  hour = Math.floor(minute / 60);
  minute = minute % 60;
  day = Math.floor(hour / 24);
  hour = hour % 24;

  return day + " N - " + hour + ":" + minute + ":" + seconds;
};

export default {
  getFacebookAvatar,
  getHumanReadableNumber,
  shuffle,
  upperCaseFirstLetter,
  getAvatarOfUser,
  convertTimeToString,
  convertTimeRemainToString,
  getFileUrl,
  numberWithCommas,
  vndFormat,
  getMessageError,
  getBase64,
  millisecondsToTime,
};
