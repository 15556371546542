import React from "react";
import {connect, ConnectedProps} from "react-redux";
import {IRootState} from "../../redux";
import * as chart from "chart.js";
import {Line} from "react-chartjs-2";
const {
  Chart: ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} = chart;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.elements.point.pointStyle = "star";
const mapStateToProps = (state: IRootState) => ({
  labels: state.vol24h.multipleDataListLabels,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const colors = [
  "rgb(255, 0, 0, 0.5)",
  // "rgb(0, 0, 255, 0.3)",
  "rgb(60, 179, 113, 0.5)",
  "rgb(238, 130, 238, 0.5)",
  "rgb(255, 165, 0, 0.5)",
  "rgb(106, 90, 205, 0.5)",
  "rgb(90, 90, 90, 0.5)",
  "rgba(255, 99, 71, 0.8)",
  "rgba(255, 99, 71, 1)",
  "rgba(255, 146, 79, 0.5)",
  "rgba(88, 146, 79, 0.5)",
  "rgba(88, 85, 79, 0.5)",
];

const DashboardScreen = React.memo(
  (
    props: PropsFromRedux & {
      type?: "percent" | "volume";
      volumeData: any[];
      title?: string;
      openInterestData: any[];
    }
  ) => {
    const {labels, volumeData, openInterestData: propOP} = props;
    const multipleData = volumeData || [];
    const openInterestData = propOP || [];
    const scales: Record<
      string,
      chart.ScaleOptionsByType<
        chart.ChartTypeRegistry[chart.ChartType]["scales"]
      >
    > = {};
    const datasets: any[] = [];
    Object.keys(multipleData).forEach((key, index) => {
      const color = colors[index % colors.length];
      const opColor = colors[(index + 3) % colors.length];
      const opChartKey = key + "-OP";
      scales[key] = {
        type: "linear" as const,
        display: false,
        position: index % 2 ? "left" : "right",
      } as any;
      scales[opChartKey] = {
        type: "linear" as const,
        display: false,
        position: index % 2 ? "left" : "right",
      } as any;

      let volumes = multipleData[key].map((item) => item.volume);
      if (volumes.length < labels.length) {
        const missingVols = Array.from(
          new Array(labels.length - volumes.length)
        ).map(() => 0);
        volumes = [...missingVols, ...volumes];
      }
      let openInterests =
        openInterestData && openInterestData[key]
          ? openInterestData[key].map((item) => item.openInterest)
          : [];
      if (openInterests.length && openInterests.length < labels.length) {
        const missingOpenInterests = Array.from(
          new Array(labels.length - openInterests.length)
        ).map(() => 0);
        openInterests = [...missingOpenInterests, ...openInterests];
      }

      datasets.push({
        label: key,
        data: volumes,
        borderColor: color,
        backgroundColor: color,
        yAxisID: key,
        tension: 0.4,
        pointRadius: 0,
      });
      if (openInterests.length) {
        datasets.push({
          label: opChartKey,
          data: openInterests,
          borderColor: opColor,
          backgroundColor: opColor,
          yAxisID: opChartKey,
          tension: 0.4,
          pointRadius: 0,
        });
      }
    });

    const options = {
      responsive: true,
      interaction: {
        mode: "index" as const,
        intersect: false,
      },
      stacked: false,
      plugins: {
        title: {
          display: true,
          text: props.title,
        },
      },
      scales,
      elements: {
        point: {
          pointStyle: "start",
        },
      },
    };

    const data = {
      labels,
      datasets,
    };

    return (
      <div>
        <Line
          options={options as any}
          data={data}
          plugins={[
            {
              id: "custom_canvas_background_color",
              beforeDraw: (chart) => {
                const {ctx} = chart;
                ctx.save();
                ctx.globalCompositeOperation = "destination-over";
                // ctx.fillStyle = "#171b26";
                ctx.fillStyle = "#000";
                ctx.fillRect(0, 0, chart.width, chart.height);
                ctx.restore();
              },
            },
          ]}
        />
      </div>
    );
  }
);

export const MultipleVolLineChart = connector(DashboardScreen);
