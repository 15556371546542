export const routeName = {
  login: "/login",
  forgotPassword: "/forgot-password",
  register: "/register",
  story: "/story",
  storyCreateOrEdit: "/story/createOrEdit",
  category: "/category",
  categoryCreateOrEdit: "/category/createOrEdit",
  admin: "/admin",
  adminCreat: "/admin/create",
  notificationPush: "/notification/push",
  notificationEditContent: "/notification/edit-content",
  user: "/user",
  profile: "/admin/profile",
  profileChangePassword: "/admin/change-password",
  //using
  topVolDiff: "/top-vol-diff",
  notification: "/notification",
  dashboard: "/dashboard",
  fibonacci: "/fibonacci",
  exchange: "/exchange",
};
