import React from "react";
import { Result } from "antd";
import "./style.css";
import { connect, ConnectedProps } from "react-redux";
import { IRootState } from "../redux";
import authAction from "../redux/auth/auth.action";

const mapStateToProps = (state: IRootState) => ({});
const mapDispatchToProps = {
  checkAuthorized: authAction.checkAuthorized,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const Main = React.memo((props: PropsFromRedux) => {

  // useEffect(() => {
  //   checkAuthorized();
  // }, [checkAuthorized]);

  return (
    <Result
      status="error"
      title="Tài khoản của bạn đã bị khóa!"
      subTitle={
        <div>
          <p style={{ margin: 0 }}>
            Có thể do bạn vừa cập nhật email hoặc bị Admin vô hiệu
          </p>
          <p style={{ margin: 0 }}>
            {" "}
            Hãy check mail hoặc liên hệ Admin để kích hoạt tài khoản
          </p>
        </div>
      }
    />
  );
});
export const UnActivated = connector(Main);
