import React from "react";
import { Table, TableProps } from "antd";

export const TableCustom = React.memo(
  <RecordType extends object = any>(props: TableProps<RecordType>) => {
    const { pagination, dataSource, ...other } = props;
    console.log("data source", dataSource);
    return (
      <Table
        {...other}
        dataSource={
          dataSource instanceof Array
            ? dataSource?.map((item, index) => ({ ...item, key: index }))
            : []
        }
        bordered
        pagination={{ showSizeChanger: true, ...pagination }}
      />
    );
  }
);
