import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { CreateOrEditSymbol, NotificationList } from "../../components/Symbol";
import { IRootState } from "../../redux";
import "./style.css";
// import {push} from 'react-router'

const mapStateToProps = (state: IRootState) => ({
});

const mapDispatchToProps = {
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const AdminScreen = React.memo((props: PropsFromRedux) => {
  return (
    <div>
      <NotificationList />
      <CreateOrEditSymbol />
    </div>
  );
});

export const Symbol = connector(AdminScreen);
