import produce from "immer";
import _ from "lodash";

import { Action, commonHandler } from "../common";
import { IAdminState, Types } from ".";
import { adminInitialState } from "./admin.initial-state";

const rawUserReducer = (state: IAdminState, action: Action) => {
  state = commonHandler(state, action, adminInitialState);
  const data = action.data;
  const message = action.message;
  switch (action.type) {
    case Types.changeFields: {
      if (data) {
        for (const key in data) {
          _.set(state, key, action.data[key]);
        }
      }
      return state;
    }

    case Types.setInfo: {
      if (data) {
        _.set(state, "info", data);
        _.set(state, "_id", data._id);
      }
      return state;
    }

    case Types.setState: {
      for (const key in data.state) {
        _.set(state, key, data.state[key]);
      }
      return state;
    }
    case Types.cleanAll: {
      return { ...adminInitialState };
    }
    case Types.cleanState: {
      _.set(state, data.stateName, adminInitialState[data.stateName]);
      return state;
    }
    case Types.mapDataToForm: {
      for (const key in data.data) {
        if (key === "_id") {
          _.set(state, "form.admin_id", data.data._id);
        } else {
          _.set(state, "form." + key, data.data[key]);
        }
      }
      return state;
    }

    case Types.createAdminRequest: {
      _.set(state, "isFetching", true);
      _.set(state, "message", "");
      return state;
    }
    case Types.createAdminSuccess: {
      const adminList = [data.data].concat(state.adminList);

      _.set(state, "isFetching", false);
      _.set(state, "adminList", [...adminList]);
      return state;
      // _.set(state)
    }
    case Types.createAdminFailure: {
      _.set(state, "isFetching", false);
      _.set(state, "message", message);
      _.set(state, "errorTitle", "Thêm mới admin thất bại");
      return state;
    }

    case Types.getAdminDetailRequest: {
      _.set(state, "isFetching", true);
      _.set(state, "message", "");
      return state;
    }
    case Types.getAdminDetailSuccess: {
      _.set(state, "isFetching", false);
      _.set(state, "detail", data.data);
      return state;
      // _.set(state)
    }
    case Types.getAdminDetailFailure: {
      _.set(state, "isFetching", false);
      _.set(state, "message", message);
      _.set(state, "errorTitle", "Lấy thông tin admin thất bại");
      return state;
    }

    case Types.changePasswordRequest: {
      _.set(state, "isFetching", true);
      _.set(state, "message", "");
      return state;
    }
    case Types.changePasswordSuccess: {
      _.set(state, "isFetching", false);
      // _.set(state, "detail", data.data);
      return state;
      // _.set(state)
    }
    case Types.changePasswordFailure: {
      _.set(state, "isFetching", false);
      _.set(state, "message", message);
      _.set(state, "errorTitle", "Đổi mật khẩu thất bại");
      return state;
    }

    case Types.updateAdminPersonalRequest: {
      _.set(state, "isFetching", true);
      _.set(state, "message", "");
      return state;
    }
    case Types.updateAdminPersonalSuccess: {
      _.set(state, "isFetching", false);
      _.set(state, "info", data.data);
      return state;
      // _.set(state)
    }
    case Types.updateAdminPersonalFailure: {
      _.set(state, "isFetching", false);
      _.set(state, "message", message);
      _.set(state, "errorTitle", "Cập nhật thông tin thất bại");
      return state;
    }

    case Types.changeAdminStatusRequest: {
      _.set(state, "isFetching", true);
      _.set(state, "message", "");
      return state;
    }
    case Types.changeAdminStatusSuccess: {
      const adminList = state.adminList;
      const index = adminList.findIndex((item) => item._id === data.data._id);
      if (index >= 0) {
        adminList[index] = { ...adminList[index], ...data.data };
      }
      _.set(state, "isFetching", false);
      _.set(state, "adminList", [...adminList]);
      return state;
      // _.set(state)
    }
    case Types.changeAdminStatusFailure: {
      _.set(state, "isFetching", false);
      _.set(state, "message", message);
      _.set(state, "errorTitle", "Lấy thông tin admin thất bại");
      return state;
    }

    case Types.getAdminListRequest: {
      _.set(state, "isFetching", true);
      _.set(state, "message", "");
      return state;
    }
    case Types.getAdminListSuccess: {
      _.set(state, "isFetching", false);
      _.set(state, "adminList", data.data);
      _.set(state, "total", data.total);
      return state;
      // _.set(state)
    }
    case Types.getAdminListFailure: {
      _.set(state, "isFetching", false);
      _.set(state, "message", message);
      _.set(state, "errorTitle", "Lấy danh sách admin thất bại");
      return state;
    }

    default:
      return state;
  }
};

export const adminReducer = produce(rawUserReducer, adminInitialState);
