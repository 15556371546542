import React from "react";
import { DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/vi_VN";
import moment from "moment";
import { SizeType } from "antd/lib/config-provider/SizeContext";

interface IRangeDateProps {
  start_time: moment.Moment;
  end_time: moment.Moment;
  size?: SizeType;
  style?: React.CSSProperties;
  onChange?: (date: [moment.Moment | null, moment.Moment | null]) => void;
}

export const DateStringFormat = "DD-MM-YYYY";

export const RangeDate = React.memo((props: IRangeDateProps) => {
  const { size, style, start_time, end_time } = props;

  // let startTime = start_time ? moment(start_time) : moment().startOf("month");
  // let end_time = end_time ? moment(end_time) : moment();

  const disabledStartDate = (start_time: moment.Moment) => {
    return start_time && start_time > end_time;
  };

  const disabledEndDate = (end_time: moment.Moment) => {
    return (
      end_time &&
      (end_time >
        moment()
          // .add(1)
          .endOf("date") ||
        end_time < start_time)
    );
  };

  const onChange = (date: [moment.Moment | null, moment.Moment | null]) => {
    if (props.onChange) {
      props.onChange(date);
    }
  };

  return (
    <div className="d-flex">
      <div className="px-1">
        <div>
          <label className="title" style={{ fontFamily: "arial" }}>
            Ngày bắt đầu:
          </label>
        </div>
        <DatePicker
          value={start_time}
          disabledDate={disabledStartDate}
          onChange={(value) => onChange([value, end_time])}
          style={{ minWidth: "150px", ...style }}
          format={DateStringFormat}
          placeholder="Ngày bắt đầu"
          size={size}
          locale={locale}
          allowClear={false}
        />
      </div>
      <div className="px-1">
        <div>
          <label className="title" style={{ fontFamily: "arial" }}>
            Ngày kết thúc:
          </label>
        </div>
        <DatePicker
          value={end_time}
          disabledDate={disabledEndDate}
          onChange={(value) => onChange([start_time, value])}
          style={{ minWidth: "150px", ...style }}
          format={DateStringFormat}
          placeholder="Ngày kết thúc"
          size={size}
          locale={locale}
          allowClear={false}
        />
      </div>
    </div>
  );
});
