import React from "react";
import * as chart from "chart.js";
import { Line } from "react-chartjs-2";
const {
  Chart: ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} = chart;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.elements.point.pointStyle = "star";

interface IProps {
  labels: string[];
  data: Record<string, number[]>;
  title?: string;
}

const colors = [
  "rgb(255, 0, 0, 0.5)",
  // "rgb(0, 0, 255, 0.3)",
  "rgb(60, 179, 113, 0.5)",
  "rgb(238, 130, 238, 0.5)",
  "rgb(255, 165, 0, 0.5)",
  "rgb(106, 90, 205, 0.5)",
  "rgb(90, 90, 90, 0.5)",
  "rgba(255, 99, 71, 0.8)",
  "rgba(255, 99, 71, 1)",
  "rgba(255, 146, 79, 0.5)",
  "rgba(88, 146, 79, 0.5)",
  "rgba(88, 85, 79, 0.5)",
];

export const MulLineChart = (props: IProps) => {
  const { labels, data: volumeData } = props;
  const multipleData = volumeData || [];
  const scales: Record<
    string,
    chart.ScaleOptionsByType<chart.ChartTypeRegistry[chart.ChartType]["scales"]>
  > = {};
  const datasets: any[] = [];

  Object.keys(multipleData).forEach((key, index) => {
    const color = colors[index % colors.length];
    scales[key] = {
      type: "linear" as const,
      display: false,
      position: index % 2 ? "left" : "right",
    } as any;

    let data = multipleData[key];
    // handle missing data
    if (data.length < labels.length) {
      const missingData = Array.from(
        new Array(labels.length - data.length)
      ).map(() => 0);
      data = [...missingData, ...data];
    }
    datasets.push({
      label: key,
      data,
      borderColor: color,
      backgroundColor: color,
      yAxisID: key,
      tension: 0.4,
      pointRadius: 0,
    });
  });

  const options = {
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: props.title,
      },
    },
    scales,
    elements: {
      point: {
        pointStyle: "start",
      },
    },
  };

  const data = {
    labels,
    datasets,
  };

  return (
    <div>
      <Line
        options={options as any}
        data={data}
        plugins={[
          {
            id: "custom_canvas_background_color",
            beforeDraw: (chart) => {
              const { ctx } = chart;
              ctx.save();
              ctx.globalCompositeOperation = "destination-over";
              // ctx.fillStyle = "#171b26";
              ctx.fillStyle = "#000";
              ctx.fillRect(0, 0, chart.width, chart.height);
              ctx.restore();
            },
          },
        ]}
      />
    </div>
  );
};
