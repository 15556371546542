/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IRootState} from "../../redux";
import {Button, Col, Input, Row} from "antd";
import {symbolActions} from "../../redux/symbol";
import {vol24hActions} from "../../redux/vol24h";
import {MultipleVolLineChart} from "./MultipleVolLineChart";
import {cacheToStorage, loadFromStorage, removeStorage} from "../../modules";
import {FaMinus} from "react-icons/fa";
import {openInterestActions} from "../../redux/open-interest";

const mapStateToProps = (state: IRootState) => ({
  symbol: state.symbol,
  vol24h: state.vol24h,
  openInterest: state.openInterest,
});

const mapDispatchToProps = {
  getSymbolList: symbolActions.getList,
  setSymbolState: symbolActions.setState,
  set24hState: vol24hActions.setState,
  getVol24hList: vol24hActions.getList,
  getMultipleList: vol24hActions.getMultipleStatistic,
  getOpenInterest: openInterestActions.getList,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
const rangeTimeValueConverter = {
  "1h": 1,
  "2h": 2,
  "4h": 4,
  "6h": 6,
  "12h": 12,
  "24h": 24,
};
const DashboardScreen = React.memo(
  (props: PropsFromRedux & {chartId: string; chartIndex?: number}) => {
    const [selectedSymbols, setSelectedSymbols] = useState("");
    const {
      setSymbolState,
      set24hState,
      getVol24hList,
      getMultipleList,
      getOpenInterest,
      symbol,
      vol24h,
      openInterest,
      chartId,
      chartIndex,
    } = props;
    const {symbolList, search} = symbol;
    const {chartIds} = vol24h;
    const {
      // symbol: vol24hSymbol,
      from_time,
      to_time,
      from_date,
      to_date,
      range_time,
      hour,
    } = vol24h.query;
    const multipleSymbols = selectedSymbols.split(" ");
    const symbolFinalList = search
      ? symbolList.filter((item) => item.symbol.includes(search.toUpperCase()))
      : symbolList;

    useEffect(() => {
      const query = {
        // symbol: vol24hSymbol,
        from_time,
        to_time,
        from_date,
        to_date,
        range_time,
        hour,
      };
      if (["1h", "2h", "4h", "6h", "12h", "24h"].includes(range_time)) {
        const rangeTimeValue = rangeTimeValueConverter[range_time];
        const hour = new Date().getHours();
        const fromTime = to_time - rangeTimeValue * 60 * 60 * 1000;

        let hourString = "";
        for (let i = 0; i <= rangeTimeValue; i++) {
          hourString += `${hour - i < 0 ? hour - i + 24 : hour - i} `;
        }
        query["hour"] = hourString.trim();
        query["from_time"] = fromTime;
        if (hour - rangeTimeValue < 0) {
          query["from_date"] = to_date - 60 * 60 * 24 * 1000;
        }
      }
      if (range_time === "2d") {
        query["from_date"] = to_date - 60 * 60 * 24 * 1000 * 2;
      }
      if (range_time === "7d") {
        query["from_date"] = to_date - 60 * 60 * 24 * 1000 * 7;
      }
      if (range_time === "30d") {
        query["from_date"] = to_date - 60 * 60 * 24 * 1000 * 30;
      }
      if (range_time === "3M") {
        query["from_date"] = to_date - 60 * 60 * 24 * 1000 * 90;
      }

      const {...remainQuery} = query;
      if (selectedSymbols) {
        getMultipleList({...remainQuery, symbols: selectedSymbols}, chartId);
        getOpenInterest(
          {
            created_from: from_time,
            created_to: to_time,
            symbols: selectedSymbols,
          },
          chartId
        );
      }
    }, [
      from_time,
      to_time,
      to_date,
      range_time,
      hour,
      selectedSymbols,
      getVol24hList,
      getMultipleList,
    ]);

    useEffect(() => {
      let symbols = loadFromStorage(chartId);
      if (!symbols) {
        symbols = "BTCUSDT";
      }
      setSelectedSymbols(symbols);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (selectedSymbols) {
        cacheToStorage(chartId, selectedSymbols);
      }
    }, [selectedSymbols]);

    const selectSymbol = (symbol: string) => {
      if (multipleSymbols?.includes(symbol)) {
        if (multipleSymbols.length === 1) {
          return;
        }
        const index = multipleSymbols.indexOf(symbol);
        multipleSymbols.splice(index, 1);
      } else {
        multipleSymbols.push(symbol);
      }
      const selected = multipleSymbols.join(" ");
      setSelectedSymbols(selected);
      set24hState({"query.symbols": selected});
    };

    const removeChart = () => {
      if (chartIds.length === 1) {
        return;
      }
      const index = chartIds.findIndex((id) => id.toString() === chartId);
      if (index === -1) {
        return;
      }
      const newChartIds = [...chartIds];
      newChartIds.splice(index, 1);
      const newMultipleData = {...vol24h.multipleData};
      if (newMultipleData[chartId]) {
        delete newMultipleData[chartId];
      }
      set24hState({chartIds: newChartIds, multipleData: newMultipleData});
      removeStorage(chartId);
    };

    return (
      <Row style={{marginBottom: "20px"}}>
        <Col md={22} lg={22} xl={22} xxl={22} xs={24} sm={24}>
          <Row>
            <Col span={24}>
              <MultipleVolLineChart
                title={`${chartIndex} - ${chartId}`}
                volumeData={vol24h.multipleData[chartId]}
                openInterestData={openInterest.openInterestData[chartId]}
                type="volume"
              />
            </Col>
          </Row>
        </Col>

        <Col
          md={2}
          lg={2}
          xl={2}
          xxl={2}
          xs={24}
          sm={24}
          style={{position: "relative"}}
        >
          <Row>
            <Col span={24} style={{maxHeight: "400px", overflow: "scroll"}}>
              <Row>
                <Col span={24}>
                  <Input
                    value={search}
                    onChange={(e) => setSymbolState({search: e.target.value})}
                    placeholder="Filter"
                  />
                </Col>
                <Col span={24}>
                  {[
                    ...multipleSymbols.map((symbol) => ({symbol})),
                    ...symbolFinalList.filter(
                      (i) => !multipleSymbols.includes(i.symbol)
                    ),
                  ].map((item) => (
                    <Button
                      key={item._id}
                      type={
                        multipleSymbols?.includes(item.symbol)
                          ? "primary"
                          : "default"
                      }
                      block
                      onClick={() => selectSymbol(item.symbol)}
                    >
                      {item.symbol}
                    </Button>
                  ))}
                </Col>
              </Row>
            </Col>
            <Button
              type="primary"
              danger
              size="large"
              style={{position: "absolute", width: "100%", bottom: 0}}
              onClick={() => removeChart()}
            >
              <FaMinus />
            </Button>
          </Row>
        </Col>
      </Row>
    );
  }
);

export const Volume = connector(DashboardScreen);
