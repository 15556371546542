import Joi from "joi";

export const adminValidation = {
  changePassword: Joi.object().keys({
    oldPassword: Joi.string().required(),
    newPassword: Joi.string()
      .min(8)
      .required(),
  }),
  updateInfo: Joi.object().keys({
    // firstName: Joi.string().trim(),
    // lastName: Joi.string().trim(),
    name: Joi.string().trim(),
    phoneNumber: Joi.string().trim(),
    avatar: Joi.string().trim(),
    email: Joi.string().email({ minDomainSegments: 2, tlds: { allow: false } }),
  }),
  updateStatus: Joi.object().keys({
    admin_id: Joi.string()
      .regex(/[a-zA-Z0-9]{24}$/, { name: "object id" })
      .required(),
    status: Joi.string()
      .valid("active", "disabled")
      .required(),
  }),
  createNew: Joi.object().keys({
    // email: Joi.string()
    //   .email({ minDomainSegments: 2 })
    //   .required(),
    email: Joi.string()
      .email({ minDomainSegments: 2, tlds: { allow: false } })
      .required(),
    password: Joi.string()
      .min(8)
      .required(),
    name: Joi.string()
      .trim()
      .required(),
    phoneNumber: Joi.string()
      .trim()
      .required(),
    avatar: Joi.string(),
  }),
};
