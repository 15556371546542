import moment from "moment";

export const ONE_MINUTE_IN_MIL_SECONDS = 60 * 1000;
export const ONE_HOUR_IN_MIL_SECONDS = 60 * 60 * 1000;
export const ONE_DAY_IN_MIL_SECONDS = 24 * 60 * 60 * 1000;
export const getNumberStepsOfTimes = (
  t1: number,
  t2: number,
  step: "1h" | "5m" | "1d"
) => {
  const delta = Math.abs(t2 - t1);
  if (step === "1h") {
    return Math.ceil(delta / ONE_HOUR_IN_MIL_SECONDS);
  }
  if (step === "5m") {
    return Math.ceil(delta / (ONE_MINUTE_IN_MIL_SECONDS * 5));
  }
  if (step === "1d") {
    return Math.ceil(delta / ONE_DAY_IN_MIL_SECONDS);
  }
  return 0;
};

export const initStatisticDataList = (params: {
  stepCount: number;
  symbol: string;
  timeFormat: string;
  minTimestamps: number;
  step: string;
}) => {
  const { step, stepCount, symbol, timeFormat, minTimestamps } = params;
  let additionalTime = ONE_HOUR_IN_MIL_SECONDS;
  let startOf = "hour";
  if (step === "1h") {
  }
  if (step === "5m") {
    additionalTime = ONE_MINUTE_IN_MIL_SECONDS * 5;
    startOf = "minute";
  }
  if (step === "1d") {
    additionalTime = ONE_DAY_IN_MIL_SECONDS;
    startOf = "day";
  }

  return Array.from(Array(stepCount - 1)).map((item, index) => {
    const timestamps = minTimestamps + index * additionalTime;
    return {
      volume: 0,
      timestamps,
      _id: "",
      time: moment(timestamps)
        //@ts-ignore
        .startOf(startOf)
        .format("YYYY-MM-DD HH:mm:ss"),
      symbol,
      timeFormat: moment(timestamps)
        //@ts-ignore
        .startOf(startOf)
        .format(timeFormat),

      percentChange: 0,
      change: 0,
    };
  });
};
