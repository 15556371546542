import { unAuthorizedHandler } from "./handler";

class Api {
  token = "";
  host = "";
  constructor(host: string) {
    this.host = host;
  }

  setToken(token: string) {
    this.token = token;
  }

  formatQuery(query: any) {
    let queryString = "?";
    for (const key in query) {
      if (!query[key]) delete query[key];
      else if (typeof query[key] != "undefined") {
        if (query[key] instanceof Array) {
          if (!query[key].length) {
            delete query[key];
          } else {
            // eslint-disable-next-line no-loop-func
            query[key].forEach((item, index) => {
              queryString += `${key}[${index}]=${item}&`;
            });
            delete query[key];
          }
          continue;
        }
        queryString += `${key}=${query[key]}&`;
      }
    }
    return queryString;
  }

  async fetchData(
    api: string,
    method = "GET",
    body: any | undefined = undefined
  ) {
    if (!api.includes(this.host) && !api.includes('https')) {
      api = this.host + api;
    }
    console.log("Method: ", method, "Api", api);
    console.log("Body", body);
    try {
      const options: any = {
        method,
        headers: {
          Accept: "application/json",
          // "Content-Type": "",
          // Authorization: "",
        },
        body,
      };
      if (typeof body === "string") {
        options.headers["Content-Type"] = "application/json;charset=UTF-8";
      } else if (body instanceof FormData) {
        // if (options.headers["Content-Type"]) {
        // delete options.headers["Content-Type"];
        // delete options.headers.Accept;
        // }
      }
      if (this.token) {
        options.headers["Authorization"] = "Bearer " + this.token;
      }
      const response = await fetch(api, options);
      try {
        const json = await response.json();
        console.log("Response of", api, json);
        if (json.statusCode === 401) {
          unAuthorizedHandler();
        }
        return { ...json, code: json.statusCode, success: response.ok };
      } catch (err) {
        console.log("response ", response);
        throw err;
      }
    } catch (err) {
      console.log("Response error'", err);
      return { code: -1, errors: err, success: false };
    }
  }
}

const AppApi = new Api(
  process.env.REACT_APP_BACKEND_HOST || "http://112.78.4.125:3001"
);
export default AppApi;

export interface ResponseInterface<T> {
  success: boolean;
  statusCode?: number;
  data?: T;
  total_page?: number;
  totalPage?: number;
  error?: number;
  errors?: any;
  message?: string;
}
export interface IBaseQuery {
  pageSize: number;
  page: number;
  sort?: string;
  status?: string;
  createdFrom?: Date;
  createdTo?: Date;
}
