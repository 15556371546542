import Joi from "joi";
import { errorDefined, errorKeyDefined } from "./errors";

export const validate = (data: any, schema: Joi.ObjectSchema) => {
  const result = schema.validate(data, {
    abortEarly: false,
    allowUnknown: false,
  });
  return result;
};

const checkLengthErrorsList = ["array.min", "string.min"];
export const getJoiError = (joiError: Joi.ValidationError) => {
  const details = joiError.details;
  console.log("error details", details);
  const errors: any = {};
  details.forEach((item) => {
    const { type, context, path } = item;

    if (checkLengthErrorsList.includes(type)) {
      errors[path[0]] =
        errorKeyDefined[path[0]] +
        " " +
        errorDefined[type].replace("{{0}}", context?.limit);
    } else {
      errors[path[0]] = errorKeyDefined[path[0]] + " " + errorDefined[type];
    }
  });
  return errors;
};
