export interface IAdmin {
  _id: null | string;
  name: string;
  token?: string;
  email: string;
  status: string;
}

export const saveAdmin = (admin: IAdmin) => {
  return localStorage.setItem("app-admin", JSON.stringify(admin));
};

export const loadAdmin = () => {
  const adminString = localStorage.getItem("app-admin");
  if (adminString) {
    try {
      const admin: IAdmin = JSON.parse(adminString);
      return admin;
    } catch (error) {}
  }
  return null;
};

export const updateAdmin = (update: any) => {
  const adminString = localStorage.getItem("app-admin");
  if (adminString) {
    try {
      const admin = JSON.parse(adminString);
      return saveAdmin({ ...admin, ...update });
    } catch (error) {}
  }
  return null;
};

export const removeAdmin = () => {
  localStorage.removeItem("app-admin");
};

export const saveBinanceSymbol = (data: any) => {
  localStorage.setItem("symbols", JSON.stringify(data));
};

export const loadBinanceSymbol = () => {
  const symbols = localStorage.getItem("symbols");
  if (symbols) {
    return JSON.parse(symbols);
  }
  return null;
};

export const saveCachingAt = () => {
  localStorage.setItem("cachingAt", Date.now() + "");
};
export const getCachingAt = () => {
  return localStorage.getItem("cachingAt") || 0;
};

export const cacheToStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const loadFromStorage = (key: string) => {
  return localStorage.getItem(key);
};

export const removeStorage = (key: string) => {
  return localStorage.removeItem(key);
};
