export interface Action {
  type: string;
  data?;
  message?: string;
}
export interface IAction extends Action {}

export const AppTypes = {
  reset: "app.reset",
};

export const resetState = () => (dispatch: any) => {
  dispatch(createAction(AppTypes.reset));
};

export function createAction<T>(type: string, data?, message?: string): Action {
  console.log("change state", type, data, message);
  return {
    type,
    data,
    message,
  };
}
