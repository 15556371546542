import { IAuthState } from "./auth.interface";

export const authInitState: IAuthState = {
  isFetching: false,
  message: null,
  isLogin: false,
  email: "",
  password: "",
  errors: {},
};
