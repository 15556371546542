import React from "react";
import { authRoutes, mainRoutes } from "./config/routes";
import { AuthLayout, MainLayout } from "./layouts";
import { Provider, connect } from "react-redux";
import { configureStore } from "./config/store";
import { ConnectedRouter } from "connected-react-router";
import { loadAdmin } from "./modules/storage";
import { IRootState, reducer } from "./redux";
import { adminAction, IAdminState } from "./redux/admin";
import authAction from "./redux/auth/auth.action";
import { history } from "./config/history";
import AppApi from "./modules/api";
// import { socket } from "./modules/socket";
import { io } from "socket.io-client";
import { vol24hActions } from "./redux/vol24h";

const socket = io(process.env.REACT_APP_BACKEND_HOST || "");

socket.on("connect", () => {
  console.log("SOCKET CONNECTED");
});

socket.on("vol-change", (payload) => {
  console.log("notification change", payload);
  store.dispatch(vol24hActions.setState({ volChangeList: payload }));
});
socket.on("fibo-change", (payload) => {
  console.log("fibo change", payload);
  store.dispatch(vol24hActions.setState({ fibonacci: payload }))
})
socket.on("disconnect", () => {
  console.log("SOCKET DISCONNECTED");
});

export const store = configureStore(reducer);
const adminLoaded = loadAdmin();
if (process.env.NODE_ENV === "production") {
  console.log = () => { };
}
store.subscribe(() => {
  console.log("change state", store.getState());
});

if (adminLoaded && adminLoaded._id) {
  store.dispatch(adminAction.setInfo(adminLoaded));
  store.dispatch(authAction.changeFields({ isLogin: true }));
  // store.dispatch(settingAction.getList({ page: 1, pageSize: 20 }));
  // settingAction.getList({ page: 1, pageSize: 20 });
  if (adminLoaded.token) {
    AppApi.setToken(adminLoaded.token);
  }
}

const App: React.FC = (props: any) => {
  const admin = props.admin as IAdminState;
  return (
    <div>
      <ConnectedRouter history={history}>
        {admin && admin._id ? (
          <MainLayout routes={mainRoutes} />
        ) : (
          <AuthLayout routes={authRoutes} />
        )}
      </ConnectedRouter>
    </div>
  );
};

// return (

const mapStateToProps = (state: IRootState) => ({
  admin: state.admin,
});

const AppWithState = connect(mapStateToProps, null)(App);

const AppWithRedux: React.FC = () => {
  return (
    <Provider store={store}>
      <AppWithState />
    </Provider>
  );
};

export default AppWithRedux;
