import produce from "immer";
import _ from "lodash";
import { Types } from "./file.action";
import { Action, commonHandler } from "../common";
import { fileInitialState } from "./file.initial-state";
import { IFileState } from "./file.interface";

const rawUserReducer = (state: IFileState, action: Action) => {
  state = commonHandler(state, action, fileInitialState);
  const data = action.data;
  const message = action.message;
  switch (action.type) {
    case Types.setState: {
      for (const key in data.state) {
        _.set(state, key, data.state[key]);
      }
      return state;
    }
    case Types.cleanAll: {
      return { ...fileInitialState };
    }

    case Types.uploadFileRequest: {
      _.set(state, "isFetching", true);
      _.set(state, "message", "");
      return state;
    }
    case Types.uploadFileSuccess: {
      _.set(state, "isFetching", false);
      _.set(state, "files", data.data);
      return state;
      // _.set(state)
    }
    case Types.uploadFileFailure: {
      _.set(state, "isFetching", false);
      _.set(state, "message", message);
      _.set(state, "errorTitle", "upload file thất bại");
      return state;
    }

    default:
      return state;
  }
};

export const fileReducer = produce(rawUserReducer, fileInitialState);
