import _ from 'lodash';
import config from '../config/setting';
import en from '../assets/languages/en.json';
import vi from '../assets/languages/vi.json';

let currentString: any = vi;
if (config.DEFAULT_LANGUAGE === 'en') {
  currentString = en;
}

function setLanguage(code: string) {
  switch (code) {
    case 'vi':
      currentString = vi;
      break;
    case 'en':
      currentString = en;
      break;
  }
}

function getLanguage() {
  switch (currentString) {
    case vi:
      return 'Vietnamese';
    case en:
      return 'English';
  }
}

function t(key: string, param = []) {
  let string = _.get(currentString, key) || _.get(en, key);
  if (string && param.length > 0) {
    for (let i = 0; i < param.length; i++) {
      try {
        const regexString = `\\{${i}\\}`;
        const regex = new RegExp(regexString, 'g');
        string = string.replace(regex, param[i]);
      } catch (error) {
        console.log(error);
      }
    }
  }
  return string;
}

export default {
  setLanguage,
  getLanguage,
  t,
};
