import AppApi from "../../modules/api";
import { IVol24Query } from "./vol24h.interface";

export default {
  getList(query: IVol24Query) {
    const url = "/api/vol24h" + AppApi.formatQuery(query);
    return AppApi.fetchData(url, "GET");
  },
  multipleStatistic(query: IVol24Query) {
    const url = "/api/vol24h/multiple" + AppApi.formatQuery(query);
    return AppApi.fetchData(url, "GET");
  },
  getTopDiff() {
    const url = "/api/vol24h/top-diff";
    return AppApi.fetchData(url, "GET");
  },
  getFibonacci(){
    const url = "/api/fibonacci";
    return AppApi.fetchData(url, "GET");
  }
};
