import AppApi from "../../modules/api";
import axios from "axios";
import {
  getCachingAt,
  loadBinanceSymbol,
  saveBinanceSymbol,
  saveCachingAt,
} from "../../modules";
export default {
  getList(query?: any) {
    const url = "/api/symbol" + AppApi.formatQuery(query);
    return AppApi.fetchData(url, "GET");
  },
  create(data: any) {
    const url = "/api/symbol";
    return AppApi.fetchData(url, "POST", JSON.stringify(data));
  },
  update(id: string, data: any) {
    const url = "/api/symbol/" + id;
    return AppApi.fetchData(url, "PUT", JSON.stringify(data));
  },
  async getAll() {
    const cachingAt = getCachingAt();
    const current = Date.now();
    if (current - Number(cachingAt) < 60 * 60 * 1000) {
      const symbols = loadBinanceSymbol();
      if (symbols) {
        return symbols;
      }
    }
    const url = "https://api.binance.com/api/v3/exchangeInfo";
    const result = await axios.get(url, {
      headers: { "Content-Type": "application/json" },
    });
    const resultList = result.data.symbols.map((item) => item.symbol);
    saveBinanceSymbol(resultList);
    saveCachingAt();
    return resultList;
  },
};
