import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { IRootState } from "../../redux";
import { TableCustom } from "../_libCustom/TableCustom";
import { ColumnsType } from "antd/lib/table";
import { IFibo } from "../../redux/vol24h";

const mapStateToProps = (state: IRootState) => ({});
const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const LoginScreen = React.memo((props: PropsFromRedux & { data: IFibo[], title?: string, loading?: boolean }) => {
  const { data, title, loading } = props;

  const columns: ColumnsType<IFibo> = [
    { title: "#", dataIndex: "key", key: "key", render: ((value, record, index) => ++index) },
    { title: "symbol", dataIndex: "symbol", key: "symbol", render: (value: string) => value.replace("USDT", "") },
    { title: "price", dataIndex: "lastPrice", key: "lastPrice" },
    { title: "high", dataIndex: "highPrice", key: "highPrice" },
    { title: "low", dataIndex: "lowPrice", key: "lowPrice" },
    {
      title: "high/low", dataIndex: "high", key: "high", render: (value, record) => {
        return (record.highPrice / record.lowPrice).toFixed(1)
      }
    }
  ];

  return (
    <div className="table-custom">
      <TableCustom loading={!!loading} title={() => title && <h5>{title}</h5>} dataSource={data} columns={columns as any} pagination={{ defaultPageSize: 20 }} />
    </div>
  );
});

export const FibonacciList = connector(LoginScreen);
