import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IRootState } from "../../redux";
import { FibonacciList } from "../../components/Fibonacci/FibonacciList";
import { vol24hActions } from "../../redux/vol24h";
import { Col, Row, Tabs } from "antd";
// import "./style.css";
// import {push} from 'react-router'

const mapStateToProps = (state: IRootState) => ({
    fibonacci: state.vol24h.fibonacci
});

const mapDispatchToProps = {
    getFibonacci: vol24hActions.getFibonacci
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const AdminScreen = React.memo((props: PropsFromRedux) => {
    const [percent, setPercent] = useState(50);
    const { getFibonacci, fibonacci } = props;
    useEffect(() => {
        getFibonacci()
    }, [getFibonacci])
    return (
        <div>
            <Tabs onChange={(key) => { setPercent(Number(key)) }} >
                <Tabs.TabPane tab={"Fibonacci 50"} key={50} />
                <Tabs.TabPane tab={"Fibonacci 62"} key={62} />
            </Tabs>
            <Row gutter={[8,8]}>
                <Col md={24} lg={24} xl={12} xxl={12} sm={24} xs={24}><FibonacciList loading={false} title="1 day" data={fibonacci[percent][1]}/></Col>
                <Col md={24} lg={24} xl={12} xxl={12} sm={24} xs={24}><FibonacciList loading={false} title="3 day" data={fibonacci[percent][3]}/></Col>
                <Col md={24} lg={24} xl={12} xxl={12} sm={24} xs={24}><FibonacciList loading={false} title="7 day" data={fibonacci[percent][7]}/></Col>
                <Col md={24} lg={24} xl={12} xxl={12} sm={24} xs={24}><FibonacciList loading={false} title="30 day" data={fibonacci[percent][30]}/></Col>
                <Col md={24} lg={24} xl={12} xxl={12} sm={24} xs={24}><FibonacciList loading={false} title="90 day" data={fibonacci[percent][90]}/></Col>
            </Row>
        </div>
    );
});

export const Fibo = connector(AdminScreen);
