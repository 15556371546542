import { ONE_HOUR_IN_MIL_SECONDS, ONE_MINUTE_IN_MIL_SECONDS } from "../../modules/times";
import { IExchangeState } from "./exchange.interface";
import dayjs from "dayjs";
const created_to = new Date(
  dayjs()
    .startOf("hours")
    .toISOString()
).getTime() +
  Math.floor(new Date().getMinutes() / 5) * 5 * ONE_MINUTE_IN_MIL_SECONDS

const state: IExchangeState = {
  isFetching: false,
  message: "",
  data: {
    future24hVol: [],
    futureInterest24hVol: [],
    futurePerInterest: [],
    futurePerSpot: [],
    spot24hVol: [],
    opPerSpot: [],
  },
  query: {
    // set created_to to the nearest previous 5 minute step 
    created_to,
    created_from: created_to - ONE_HOUR_IN_MIL_SECONDS * 2,
    timeSelector: '2h'
  },
  symbolPrices: {
    btc: []
  },
  labels: [],
};
export default state;

console.log(
  "START OF HOUR",
  new Date(
    dayjs()
      .startOf("hours")
      .toISOString()
  ).getTime() +
    Math.floor(new Date().getMinutes() / 5) * 5 * ONE_MINUTE_IN_MIL_SECONDS
);
