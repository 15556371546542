import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { IRootState } from "../redux";
import { adminAction } from "../redux/admin";
import { Button, Form, Input, Modal, Spin } from "antd";

const mapStateToProps = (state: IRootState) => ({
  form: state.admin.form,
  visible: state.admin.changepassVisible,
  isFetching: state.admin.isFetching
});
const mapDispatchToProps = {
    setAdminState: adminAction.setState,
    changePass: adminAction.changePassword
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const CreateOrEdit = React.memo((props: PropsFromRedux) => {
  const {form, visible,isFetching, setAdminState,changePass} = props;
  const onCancel = () => {
    setAdminState({ "form.newPassword": "", changepassVisible: false, message: '' });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if(form.newPassword){
        changePass({newPassword: form.newPassword}).then(result => {
            if(result){
                onCancel()
            }
        })
    }
  };

  return (
    <Spin spinning={isFetching}>
      <Modal visible={visible} onCancel={onCancel} footer={null}>
        <Form>
          <Input
            placeholder="Nhập password"
            value={form.newPassword
            }
            type="password"
            onChange={(e) => setAdminState({ "form.newPassword": e.target.value })}
          />
          <div style={{display:'flex'}}>
          <Button onClick={onSubmit} style={{margin:'auto'}} htmlType="submit">submit</Button>
          </div>
        </Form>
      </Modal>
    </Spin>
  );
});

export const ChangePassword = connector(CreateOrEdit);
