import utils from "../../modules/utils";
import { createAction } from "../common";
import symbolApi from "./symbol.api";
import { ISymbolState } from "./symbol.interface";

export const Types = {
  setState: "symbol.setState",
  cleanAll: "symbol.cleanAll",
  resetState: "symbol.resetState",

  getSymbolListRequest: "Symbol.get-list.request",
  getSymbolListSuccess: "Symbol.get-list.success",
  getSymbolListFailure: "Symbol.get-list.failure",

  createRequest: "Symbol.create.request",
  createSuccess: "Symbol.create.success",
  createFailure: "Symbol.create.failure",

  updateRequest: "Symbol.update.request",
  updateSuccess: "Symbol.update.success",
  updateFailure: "Symbol.update.failure",

  getAllRequest: "Symbol.getAll.request",
  getAllSuccess: "Symbol.getAll.success",
  getAllFailure: "Symbol.getAll.failure",
};

const setState = (state: Record<string, any>) =>
  createAction(Types.setState, { state });
const resetState = (stateName: keyof ISymbolState) =>
  createAction(Types.resetState, { stateName });
const cleanAll = () => createAction(Types.cleanAll);

const getList = (query?: any) => (dispatch) => {
  dispatch(createAction(Types.getSymbolListRequest));
  return symbolApi.getList(query).then((res) => {
    if (res.success) {
      dispatch(
        createAction(Types.getSymbolListSuccess, {
          data: res.data,
          total: res.total,
        })
      );
      return true;
    } else {
      const message = utils.getMessageError(res.errors);
      dispatch(createAction(Types.getSymbolListFailure, {}, message));
      return false;
    }
  });
};

const create = (data: any) => (dispatch) => {
  dispatch(createAction(Types.createRequest));
  return symbolApi.create(data).then((res) => {
    if (res.success) {
      dispatch(
        createAction(Types.createSuccess, {
          data: res.data,
          total: res.total,
        })
      );
      return true;
    } else {
      const message = utils.getMessageError(res.errors);
      dispatch(createAction(Types.createFailure, {}, message));
      return false;
    }
  });
};

const update = (id: string, data: any) => (dispatch) => {
  dispatch(createAction(Types.updateRequest));
  return symbolApi.update(id, data).then((res) => {
    if (res.success) {
      dispatch(
        createAction(Types.updateSuccess, {
          data: res.data,
          total: res.total,
        })
      );
      return true;
    } else {
      const message = utils.getMessageError(res.errors);
      dispatch(createAction(Types.updateFailure, {}, message));
      return false;
    }
  });
};

const getAll = () => (dispatch) => {
  dispatch(createAction(Types.getAllRequest));
  return symbolApi.getAll().then((res) => {
      dispatch(
        createAction(Types.getAllSuccess, {
          data: res
        })
      );
      return true;
  });
};

export const symbolActions = {
  setState,
  resetState,
  cleanAll,
  getList,
  update,
  create,
  getAll
};
