import { IAdmin, loadAdmin, saveAdmin } from "../../modules";
import utils from "../../modules/utils";
import { createAction } from "../common";
import { adminApi } from "./admin.api";
import { adminHandler } from "./admin.handler";
import {
  IAdminCreate,
  IAdminQuery,
  IAdminUpdate,
  IChangeAdminStatus,
  IAdminChangePassword,
} from "./admin.interface";

export const Types = {
  changeFields: "admin.change-fields",
  setInfo: "admin.set-user",
  setState: "admin.set-state",
  cleanAll: "admin.clean-all",
  cleanState: "admin.clean-state",

  getAdminListRequest: "admin.get-list.request",
  getAdminListSuccess: "admin.get-list.success",
  getAdminListFailure: "admin.get-list.failure",

  getAdminDetailRequest: "admin.get-detail.request",
  getAdminDetailSuccess: "admin.get-detail.success",
  getAdminDetailFailure: "admin.get-detail.failure",

  updateAdminPersonalRequest: "admin.update-admin-personal.request",
  updateAdminPersonalSuccess: "admin.update-admin-personal.success",
  updateAdminPersonalFailure: "admin.update-admin-personal.failure",

  createAdminRequest: "admin.create-admin.request",
  createAdminSuccess: "admin.create-admin.success",
  createAdminFailure: "admin.create-admin.failure",

  changePasswordRequest: "admin.change-password.request",
  changePasswordSuccess: "admin.change-password.success",
  changePasswordFailure: "admin.change-password.failure",

  changeAdminStatusRequest: "admin.change-admin-status.request",
  changeAdminStatusSuccess: "admin.change-admin-status.success",
  changeAdminStatusFailure: "admin.change-admin-status.failure",

  mapDataToForm: "admin.map-data-to-form",
};

const changeFields = (object: any = {}) =>
  createAction(Types.changeFields, object);

const setInfo = (data: any = {}) => createAction(Types.setInfo, data);
const setState = (state: any = {}) => createAction(Types.setState, { state });
const cleanAll = () => createAction(Types.cleanAll);
const mapDataToForm = (data: IAdmin) =>
  createAction(Types.mapDataToForm, { data });
const cleanState = (stateName: string) =>
  createAction(Types.cleanState, { stateName });

const getList = (query: IAdminQuery) => (dispatch) => {
  dispatch(createAction(Types.getAdminListRequest));
  return adminApi.getList(query).then((res) => {
    if (res.success) {
      dispatch(
        createAction(Types.getAdminListSuccess, {
          data: res.data,
          total: res.total,
        })
      );
      return true;
    } else {
      // const message = utils.getMessageError(res.errors);
      dispatch(createAction(Types.getAdminListFailure, {}, res.message));
      return false;
    }
  });
};

const getById = (id: string) => (dispatch) => {
  dispatch(createAction(Types.getAdminDetailRequest));
  return adminApi.getById(id).then((res) => {
    if (res.success) {
      dispatch(createAction(Types.getAdminDetailSuccess, { data: res.data }));
      return true;
    } else {
      const message = utils.getMessageError(res.errors);
      dispatch(createAction(Types.getAdminDetailFailure, {}, message));
      return false;
    }
  });
};

const updatePersonalInfo = (doc: IAdminUpdate) => (dispatch) => {
  dispatch(createAction(Types.updateAdminPersonalRequest));
  return adminApi.updatePersonalInfo(doc).then((res) => {
    if (res.success) {
      const admin = loadAdmin();
      saveAdmin({ ...admin, ...res.data });
      dispatch(
        createAction(Types.updateAdminPersonalSuccess, { data: res.data })
      );
      return true;
    } else {
      const message = utils.getMessageError(res.errors);
      dispatch(createAction(Types.updateAdminPersonalFailure, {}, message));
      return false;
    }
  });
};

const create = (doc: IAdminCreate) => (dispatch) => {
  dispatch(createAction(Types.createAdminRequest));
  return adminApi.create(doc).then((res) => {
    if (res.success) {
      dispatch(createAction(Types.createAdminSuccess, { data: res.data }));
      return true;
    } else {
      const message = utils.getMessageError(res.errors);
      dispatch(createAction(Types.createAdminFailure, {}, message));
      return false;
    }
  });
};

const changePassword = (doc: IAdminChangePassword) => (dispatch) => {
  dispatch(createAction(Types.changePasswordRequest));
  return adminApi.changePassword(doc).then((res) => {
    if (res.success) {
      dispatch(createAction(Types.changePasswordSuccess, { data: res.data }));
      adminHandler.cleanAllState(dispatch);
      return true;
    } else {
      dispatch(createAction(Types.changePasswordFailure, {}, res.message));
      return false;
    }
  });
};

const changeAdminStatus = (doc: IChangeAdminStatus) => (dispatch) => {
  dispatch(createAction(Types.changeAdminStatusRequest));
  return adminApi.changeStatus(doc).then((res) => {
    if (res.success) {
      dispatch(
        createAction(Types.changeAdminStatusSuccess, { data: res.data })
      );
      return true;
    } else {
      const message = utils.getMessageError(res.errors);
      dispatch(createAction(Types.changeAdminStatusFailure, {}, message));
      return false;
    }
  });
};

export const adminAction = {
  changeFields,
  setInfo,
  setState,
  cleanAll,
  getList,
  getById,
  updatePersonalInfo,
  changeAdminStatus,
  changePassword,
  create,
  mapDataToForm,
  cleanState,
};
