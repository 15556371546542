import utils from "../../modules/utils";
import { createAction } from "../common";
import vol24hApi from "./vol24h.api";
import { IVol24hState, IVol24Query } from "./vol24h.interface";

export const Types = {
  setState: "vol24h.setState",
  cleanAll: "vol24h.cleanAll",
  resetState: "vol24h.resetState",

  getVol24hListRequest: "Vol24h.get-list.request",
  getVol24hListSuccess: "Vol24h.get-list.success",
  getVol24hListFailure: "Vol24h.get-list.failure",

  getMultipleStatisticRequest: "Vol24h.get-multiple-list.request",
  getMultipleStatisticSuccess: "Vol24h.get-multiple-list.success",
  getMultipleStatisticFailure: "Vol24h.get-multiple-list.failure",

  getTopDiffRequest: "Vol24h.get-top-diff.request",
  getTopDiffSuccess: "Vol24h.get-top-diff.success",
  getTopDiffFailure: "Vol24h.get-top-diff.failure",

  getFibonacciRequest: "Vol24h.get-fibonacci.request",
  getFibonacciSuccess: "Vol24h.get-fibonacci.success",
  getFibonacciFailure: "Vol24h.get-fibonacci.failure",
};

const setState = (state: Record<string, any>) =>
  createAction(Types.setState, { state });
const resetState = (stateName: keyof IVol24hState) =>
  createAction(Types.resetState, { stateName });
const cleanAll = () => createAction(Types.cleanAll);

const getList = (query: IVol24Query) => (dispatch) => {
  dispatch(createAction(Types.getVol24hListRequest));
  return vol24hApi.getList(query).then((res) => {
    if (res.success) {
      dispatch(
        createAction(Types.getVol24hListSuccess, {
          data: res.data,
          // total: res.total,
        })
      );
      return true;
    } else {
      const message = utils.getMessageError(res.errors);
      dispatch(createAction(Types.getVol24hListFailure, {}, message));
      return false;
    }
  });
};

const getMultipleStatistic = (query: IVol24Query, key: string) => (dispatch) => {
  dispatch(createAction(Types.getMultipleStatisticRequest));
  return vol24hApi.multipleStatistic(query).then((res) => {
    if (res.success) {
      dispatch(
        createAction(Types.getMultipleStatisticSuccess, {
          data: res.data,
          meta: res.meta,
          key
          // total: res.total,
        })
      );
      return true;
    } else {
      const message = utils.getMessageError(res.errors);
      dispatch(createAction(Types.getMultipleStatisticFailure, {}, message));
      return false;
    }
  });
};

const getTopDiff = () => (dispatch) => {
  dispatch(createAction(Types.getTopDiffRequest));
  return vol24hApi.getTopDiff().then((res) => {
    if (res.success) {
      dispatch(
        createAction(Types.getTopDiffSuccess, {
          data: res.data,
        })
      );
      return true;
    } else {
      const message = utils.getMessageError(res.errors);
      dispatch(createAction(Types.getTopDiffFailure, {}, message));
      return false;
    }
  });
};

const getFibonacci = () => (dispatch) => {
  dispatch(createAction(Types.getFibonacciRequest));
  return vol24hApi.getFibonacci().then((res) => {
    if (res.success) {
      dispatch(
        createAction(Types.getFibonacciSuccess, {
          data: res.data,
        })
      );
      return true;
    } else {
      const message = utils.getMessageError(res.errors);
      dispatch(createAction(Types.getFibonacciFailure, {}, message));
      return false;
    }
  });
};

export const vol24hActions = {
  setState,
  resetState,
  cleanAll,
  getList,
  getMultipleStatistic,
  getTopDiff,
  getFibonacci
};
