import produce from "immer";
import {IAction} from "../common";
import exchangeInitialState from "./exchange.initial-state";
import {IExchange, IExchangeState} from "./exchange.interface";
import _ from "lodash";
import {Types} from "./exchange.action";
import {ONE_HOUR_IN_MIL_SECONDS} from "../../modules/times";
import dayjs from "dayjs";

export const exchangeReducer = produce(
  (state: IExchangeState, action: IAction) => {
    const data = action.data;
    const message = action.message;
    switch (action.type) {
      case Types.setState: {
        const updatedState = data.state;
        for (const key in updatedState) {
          _.set(state, key, updatedState[key]);
        }
        return state;
      }
      case Types.resetState: {
        const stateName: keyof IExchangeState = data.stateName;
        _.set(state, stateName, exchangeInitialState[stateName]);
        return state;
      }
      case Types.cleanAll: {
        return exchangeInitialState;
      }

      case Types.getExchangesRequest: {
        _.set(state, "isFetching", true);
        _.set(state, "message", "");
        return state;
      }
      case Types.getExchangesSuccess: {
        const exchangeData: IExchange[] = data.data;
        const future24hVol: number[] = [];
        const futureInterest24hVol: number[] = [];
        const futurePerInterest: number[] = [];
        const futurePerSpot: number[] = [];
        const opPerSpot: number[] = [];
        const spot24hVol: number[] = [];
        const labels: string[] = [];
        const timeFormat = getTimeFormat(
          state.query.created_from,
          state.query.created_to
        );
        exchangeData.forEach((item) => {
          labels.push(
            dayjs(item.created_at, "Asia/Ho_Chi_Minh").format(timeFormat)
          );
          future24hVol.push(item.future_24h_volume);
          futureInterest24hVol.push(item.future_24h_open_interest);
          futurePerInterest.push(Number(item.futurePerInterest));
          futurePerSpot.push(Number(item.futurePerSpot));
          opPerSpot.push(Number(item.opPerSpot));
          spot24hVol.push(item.spot_24h_volume);
        });

        _.set(state, "isFetching", false);
        _.set(state, "data", {
          exchangeData,
          future24hVol,
          futureInterest24hVol,
          futurePerInterest,
          futurePerSpot,
          opPerSpot,
          spot24hVol,
        });
        _.set(state, "labels", labels);
        return state;
        // _.set(state)
      }
      case Types.getExchangesFailure: {
        _.set(state, "isFetching", false);
        _.set(state, "message", message);
        return state;
      }

      case Types.getBtcPricesRequest: {
        _.set(state, "isFetching", true);
        _.set(state, "message", "");
        return state;
      }
      case Types.getBtcPricesSuccess: {
        const klines = data.data;
        const prices = klines.map((item) => {
          return Number(item[4]);
        });
        const symbolPrices = state.symbolPrices;
        symbolPrices.btc = prices;
        _.set(state, "symbolPrices", symbolPrices);
        _.set(state, "isFetching", false);
        return state;
        // _.set(state)
      }
      case Types.getBtcPricesFailure: {
        _.set(state, "isFetching", false);
        _.set(state, "message", message);
        return state;
      }

      default: {
        return state;
      }
    }
  },
  exchangeInitialState
);

const getTimeFormat = (from: number | undefined, to: number | undefined) => {
  let timeFormat = "DD/MM HH:mm";
  if (from && to) {
    const period = Math.floor((to - from) / ONE_HOUR_IN_MIL_SECONDS);
    if (period <= 12) {
      return "HH:mm";
    }
    if (period <= 24) {
      return "DD/MM HH:mm";
    }
    if (period <= 24 * 30) {
      return "DD/MM HH:mm";
    }
  }
  return timeFormat;
};
