import utils from "../../modules/utils";
import { createAction } from "../common";
import { fileApi } from "./file.api";
import { IUploadFile } from "./file.interface";

export const Types = {
  setState: "file.set-state",
  cleanAll: "file.clean-all",

  uploadFileRequest: "file.get-list.request",
  uploadFileSuccess: "file.get-list.success",
  uploadFileFailure: "file.get-list.failure",
};
const setState = (state: any = {}) => createAction(Types.setState, { state });
const cleanAll = () => createAction(Types.cleanAll);

const upload = (params: IUploadFile, fileList: any[]) => (
  dispatch
): Promise<string[] | false> => {
  dispatch(createAction(Types.uploadFileRequest));
  return fileApi.upload(params, fileList).then((res) => {
    if (res.success) {
      dispatch(createAction(Types.uploadFileSuccess, { data: res.data }));
      return res.data;
    } else {
      const message = utils.getMessageError(res.errors);
      dispatch(createAction(Types.uploadFileFailure, {}, message));
      return false;
    }
  });
};

export const fileAction = {
  setState,
  cleanAll,
  upload,
};
