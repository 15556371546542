export const errorDefined = {
  "string.empty": "không được để trống",
  "array.min": "phải có ít nhất {{0}}",
  "string.email": "Email không hợp lệ",
  "string.min": "phải có ít nhất {{0}} ký tự",
};

export const errorKeyDefined = {
  name: "Tên",
  description: "Mô tả",
  genderList: "Giới tính",
  ageList: "Tuổi",
  categoryIdList: "Danh mục",
  imageList: "Hình ảnh",
  url: "File",
  author: "Tác giả",
  image: "Hình ảnh",
  password: "mật khẩu",
  confirmPassword: "Mật khẩu xác nhận",
  email: "Email",
  phoneNumber: "Số điện thoại",
  title: "Tiêu đề",
  content: "Nội dung",
  oldPassword: "Mật khẩu hiện tại",
  newPassword: "Mật khẩu mới",
  readerName:"Người đọc"
  // isFree: ""
};
