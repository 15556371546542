import produce from "immer";
import _ from "lodash";

import { Action, commonHandler } from "../common";
import { authInitState, IAuthState, Types } from "./index";

const rawUserReducer = (state: IAuthState, action: Action) => {
  state = commonHandler(state, action, authInitState);
  const data = action.data;
  const message = action.message;
  switch (action.type) {
    case Types.changeFields:
      if (data) {
        for (const key in data) {
          _.set(state, key, action.data[key]);
        }
      }
      return state;
    case Types.loginRequest:
      _.set(state, "isFetching", true);
      _.set(state, "message", null);
      return state;
    case Types.loginSuccess:
      _.set(state, "isFetching", false);
      _.set(state, "message", null);
      _.set(state, "isLogin", true);
      return state;
    case Types.loginFailure:
      _.set(state, "isFetching", false);
      _.set(state, "message", message);
      return state;

    case Types.resetPasswordRequest:
      _.set(state, "isFetching", true);
      _.set(state, "message", null);
      return state;
    case Types.resetPasswordSuccess:
      _.set(state, "isFetching", false);
      _.set(state, "message", null);
      return state;
    case Types.resetPasswordFailure:
      _.set(state, "isFetching", false);
      _.set(state, "message", message);
      return state;

    case Types.setState: {
      const { state: stateUpdate } = data;
      for (const key in stateUpdate) {
        _.set(state, key, stateUpdate[key]);
      }
      return state;
    }
    case Types.cleanAll: {
      return { ...authInitState };
    }
    default:
      return state;
  }
};

export const authReducer = produce(rawUserReducer, authInitState);
