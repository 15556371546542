import produce from "immer";
import {IAction} from "../common";
import openInterestInitialState from "./open-interest.initial-state";
import {IOpenInterestState} from "./open-interest.interface";
import _ from "lodash";
import {Types} from "./open-interest.action";

export const openInterestReducer = produce(
  (state: IOpenInterestState, action: IAction) => {
    const data = action.data;
    const message = action.message;
    switch (action.type) {
      case Types.setState: {
        const updatedState = data.state;
        for (const key in updatedState) {
          _.set(state, key, updatedState[key]);
        }
        return state;
      }
      case Types.resetState: {
        const stateName: keyof IOpenInterestState = data.stateName;
        _.set(state, stateName, openInterestInitialState[stateName]);
        return state;
      }
      case Types.cleanAll: {
        return openInterestInitialState;
      }

      case Types.getOpenInterestsRequest: {
        _.set(state, "isFetching", true);
        _.set(state, "message", "");
        return state;
      }
      case Types.getOpenInterestsSuccess: {
        const key = data.key;
        const openInterestData = {};

        data.data.forEach((item) => {
          if (!openInterestData[item.symbol]) {
            openInterestData[item.symbol] = [];
          }
          openInterestData[item.symbol].push(item);
        });
        state.openInterestData[key] = openInterestData;
        _.set(state, "isFetching", false);
        return state;
        // _.set(state)
      }
      case Types.getOpenInterestsFailure: {
        _.set(state, "isFetching", false);
        _.set(state, "message", message);
        return state;
      }

      case Types.getOpenInterestsMemeRequest: {
        _.set(state, "isFetching", true);
        _.set(state, "message", "");
        return state;
      }
      case Types.getOpenInterestsMemeSuccess: {
        const stickers = data.stickers;
        const stickerKv = {};
        stickers.forEach((item) => {
          stickerKv[item.symbol] = Number(item.price);
        });
        const result = {};

        data.data.forEach((item) => {
          const createdAt = item.created_at;
          if (!result[createdAt]) {
            result[createdAt] = 0;
          }
          result[createdAt] += item.openInterest * stickerKv[item.symbol];
        });
        const memeData: number[] = [];
        Object.keys(result)
          .sort()
          .forEach((key) => {
            memeData.push(result[key]);
          });
        _.set(state, "isFetching", false);
        _.set(state, "memeData", memeData);
        return state;
        // _.set(state)
      }
      case Types.getOpenInterestsMemeFailure: {
        _.set(state, "isFetching", false);
        _.set(state, "message", message);
        return state;
      }

      default: {
        return state;
      }
    }
  },
  openInterestInitialState
);
