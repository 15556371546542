import React from "react";
import { Row, Col, Avatar, Layout, Tooltip, Menu, Dropdown } from "antd";
import userIMG from "../assets/images/user.png";
import { FiLogOut } from "react-icons/fi";
import { connect, ConnectedProps } from "react-redux";
import { IRootState } from "../redux";
import authAction from "../redux/auth/auth.action";
import utils from "../modules/utils";
import { routeName } from "../config/route-name";
import { push } from "connected-react-router";
import { IoMdArrowDropdown } from "react-icons/io";
import {  FaLock } from "react-icons/fa";
import { ChangePassword } from "./ChangePass";
import { adminAction } from "../redux/admin";

const { Header: AntHeader } = Layout;

const mapStateToProps = (state: IRootState) => ({
  admin: state.admin.info,
});
const mapDispatchToProps = {
  logout: authAction.logout,
  setAdminState: adminAction.setState,
  direct: push,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const dropDownMenuItem = [
  // {
  //   route: routeName.admin,
  //   text: "Chỉnh sửa profile",
  //   icon: <FaEdit style={{ margin: "auto", fontSize: "12px" }} />,
  //   direct: routeName.profile,
  // },
  {
    route: routeName.admin,
    text: "Đổi mật khẩu",
    icon: <FaLock style={{ margin: "auto", fontSize: "12px" }} />,
    key:'changepass'
    // direct: routeName.profileChangePassword,
  },
];

const HeaderScreen = React.memo((props: PropsFromRedux) => {
  // const { breadcrumbList } = props;
  const { logout, setAdminState, admin } = props;

  const menu = (
    <Menu>
      {dropDownMenuItem.map((item, index) => {
        return (
          <Menu.Item
            key={index}
            onClick={() => {
              if(item.key === 'changepass'){
                setAdminState({changepassVisible: true})
              }
              // direct(item.direct);
            }}
          >
            <Row>
              <Col style={{ display: "flex" }} span={4}>
                {item.icon}
              </Col>
              <Col style={{ display: "flex" }} span={20}>
                <span style={{ margin: "auto", marginLeft: "0px" }}>
                  {" "}
                  {item.text}
                </span>
              </Col>
            </Row>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <AntHeader className="headerNavbar">
      <Row>
        <Col span={24}>
          <div className="header-navbar">
            <div style={{ display: "flex" }}>
              <i style={{ marginRight: "4px", margin: "auto" }}>
                ver:{" "}
                <span style={{ fontWeight: 500 }}>
                  {" "}
                  {process.env.REACT_APP_VERSION}
                </span>
              </i>
            </div>
            <Dropdown overlay={menu}>
              <div className="action-header">
                <Avatar
                  size={28}
                  className="m-auto mr-3"
                  style={{ cursor: "pointer" }}
                  src={admin.avatar ? utils.getFileUrl(admin.avatar) : userIMG}
                />
                <p
                  style={{
                    fontSize: 12,
                    marginBottom: 0,
                    textAlign: "left",
                    marginLeft: "15px",
                  }}
                >
                  <span style={{ cursor: "pointer" }}>{admin.name}</span>
                </p>
                <IoMdArrowDropdown />
              </div>
            </Dropdown>
            <Tooltip title="Đăng xuất" placement="right">
              <div
                // onClick={() => this.props.logout()}
                className="action-header"
                style={{ cursor: "pointer" }}
                onClick={logout}
              >
                <FiLogOut />
              </div>
            </Tooltip>
          </div>
        </Col>
      </Row>
      <ChangePassword />
    </AntHeader>
  );
});
// const Screen = React.memo((props: any) => {
//   return <div></div>;
// });

export const Header = connector(HeaderScreen);
