import React, { useEffect, useState } from "react";
import { Upload } from "antd";
import { FaTrash, FaUpload } from "react-icons/fa";
import { UploadFile } from "antd/lib/upload/interface";
import { Button } from "antd/lib/radio";
import utils from "../../modules/utils";

export interface IRadioFile {
  name: string;
  type?: string;
  size?: number;
  uid: string;
  url?: string;
  status: string;
  originFileObject?: IRadioFile;
}
interface IUpload {
  urlList?: string[];
  customRef?: any;
  onChange?: (file: UploadFile[]) => void;
  onGetDuration?: (duration: number) => void;
}

export const RadioUpload = React.memo((props: IUpload) => {
  const { urlList, onChange } = props;
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    if (urlList?.length) {
      const file: any = {
        name: "url",
        uid: new Date().getTime().toString(36),
        status: "done",
        url: utils.getFileUrl(urlList[0]),
      };
      setFileList([file]);
    }
  }, [urlList]);

  const onRequest = async (options: any) => {
    // const file: UploadFile = {
    //   ...options.file,
    //   originFileObj: options.file,
    //   status: "done",
    //   url: await utils.getBase64(options.file),
    // };
    // const audio = new Audio();
    // if (file.url) {
    //   audio.src = file.url;
    // }
    // audio.addEventListener("loadedmetadata", function() {
    //   if (props.onGetDuration) {
    //     props.onGetDuration(audio.duration);
    //   }
    // });
    // // const newList: UploadFile[] = [...fileList, file];
    // setFileList([file]);
    // if (onChange) {
    //   onChange([file]);
    // }
    // setFileList([...fileList, file]);
  };

  const onFileChange = async ({ fileList: imageList }) => {
    console.log("image list", imageList);
    if (!imageList.length) {
      return;
    }
    // const options = {
    //   file: imageList[0].originFileObj,
    // };
    const originFileObj = imageList[0].originFileObj;
    const file: UploadFile = {
      ...originFileObj,
      originFileObj: originFileObj,
      status: "done",
      url: await utils.getBase64(originFileObj),
    };
    const audio = new Audio();
    if (file.url) {
      audio.src = file.url;
    }
    audio.addEventListener("loadedmetadata", function() {
      if (props.onGetDuration) {
        props.onGetDuration(audio.duration);
      }
    });
    // const newList: UploadFile[] = [...fileList, file];
    setFileList([file]);
    if (onChange) {
      onChange([file]);
    }
  };

  const onRemoveImage = (file) => {
    setFileList([]);
    if (onChange) {
      onChange([]);
    }
  };
  return (
    <div className={fileList.length ? "audio" : ""}>
      {/* <div> */}
      <Upload
        customRequest={onRequest}
        onRemove={onRemoveImage}
        fileList={fileList}
        listType="picture-card"
        onChange={onFileChange}
        accept={".mp3, .mp4"}
      >
        {fileList.length ? null : (
          <Button>
            <FaUpload />
          </Button>
        )}
      </Upload>
      {fileList.length ? (
        <div style={{ display: "flex" }}>
          <figure className="m-0">
            <audio controls src={fileList[0].url} />
          </figure>
          <FaTrash
            style={{ margin: "auto", cursor: "pointer" }}
            onClick={onRemoveImage}
          />
        </div>
      ) : null}
      {/* <Modal visible={previewVisible} onCancel={handleCancel}>
        <img alt="image" style={{ width: "100%" }} src={previewImage} />
      </Modal> */}
    </div>
  );
});
