import { IVol24hState } from "./vol24h.interface";
import moment from "moment";

const state: IVol24hState = {
  isFetching: false,
  message: "",
  query: {
    to_time: Date.now(),
    from_time: Date.now() - 60 * 60 * 2 * 1000,
    from_date: new Date(moment().format("YYYY-MM-DD")).getTime(),
    to_date: new Date(moment().format("YYYY-MM-DD")).getTime(),
    hour: `${new Date().getHours()} ${new Date().getHours() - 1}`,
    range_time: "2h",
    symbol: "BTCUSDT",
    symbols: "BTCUSDT",
  },
  dataList: [],
  multipleDataList: [],
  multipleData: {},
  multipleDataListLabels: [],
  topDiff: {},
  volChangeList: [],
  minVolume: 0,
  multipleMinVol: 0,
  chartCount: 1,
  chartIds: [],
  fibonacci: {
    isInprogress: true,
    "62": { "1": [], "3": [], "7": [], "30": [], "90": [] },
    "50": { "1": [], "3": [], "7": [], "30": [], "90": [] },
  },
};
export default state;
