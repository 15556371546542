/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IRootState } from "../../redux";
import { Col, Row, Table } from "antd";
import { vol24hActions } from "../../redux/vol24h";
import { ColumnType } from "antd/lib/table";

const mapStateToProps = (state: IRootState) => ({
  vol24h: state.vol24h,
});

const mapDispatchToProps = {
  getTopVolDiff: vol24hActions.getTopDiff,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
const DashboardScreen = React.memo((props: PropsFromRedux) => {
  const { vol24h, getTopVolDiff } = props;
  const { topDiff } = vol24h;
  const { to_time } = vol24h.query;
  //@ts-ignore
  const { m10Data = [], h1Data = [], h4Data = [], d1Data = [] } = topDiff;
  useEffect(() => {
    getTopVolDiff();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      getTopVolDiff();
    }, 5000);
  }, [to_time, getTopVolDiff]);

  const columns: ColumnType<any>[] = [
    {
      title: <strong style={{ fontWeight: 400 }}>#</strong>,
      dataIndex: "index",
      key: "index",
      render(value, record, index) {
        return ++index;
      },
      fixed: "left",
      width: "40px",
    },
    {
      title: <strong style={{ fontWeight: 400 }}>Token</strong>,
      dataIndex: "symbol",
      key: "symbol",
      fixed: "left",
      width: "120px",
      render: (value) => value.replace("USDT","")
    },

    {
      title: "Vol cũ",
      dataIndex: "prevVol",
      key: "prevVol",
      render(value: number, record, index) {
        return value.toLocaleString();
      },
    },

    {
      title: "Vol hiện tại",
      dataIndex: "currentVol",
      key: "currentVol",
      render(value: number, record, index) {
        return value.toLocaleString();
      },
    },

    {
      title: "% tăng",
      dataIndex: "diffPercent",
      key: "diffPercent",
    },
  ];

  return (
    <Row>
      <Col
        style={{ padding: "10px" }}
        xxl={12}
        xl={12}
        lg={12}
        md={24}
        sm={24}
        xs={24}
      >
        <Table
          pagination={false}
          dataSource={m10Data}
          columns={columns}
          title={() => <strong> 10 Phút</strong>}
          scroll={{ x: 400 }}
        />
      </Col>

      <Col
        style={{ padding: "10px" }}
        xxl={12}
        xl={12}
        lg={12}
        md={24}
        sm={24}
        xs={24}
      >
        <Table
          pagination={false}
          dataSource={h1Data}
          columns={columns}
          title={() => <strong>1 Giờ</strong>}
          scroll={{ x: 400 }}
        />
      </Col>

      <Col
        style={{ padding: "10px" }}
        xxl={12}
        xl={12}
        lg={12}
        md={24}
        sm={24}
        xs={24}
      >
        <Table
          pagination={false}
          dataSource={h4Data}
          columns={columns}
          title={() => <strong>4 Giờ</strong>}
          scroll={{ x: 400 }}
        />
      </Col>

      <Col
        style={{ padding: "10px" }}
        xxl={12}
        xl={12}
        lg={12}
        md={24}
        sm={24}
        xs={24}
      >
        <Table
          pagination={false}
          dataSource={d1Data}
          columns={columns}
          title={() => <strong>1 Ngày</strong>}
          scroll={{ x: 400 }}
        />
      </Col>
    </Row>
  );
});

export const TopVolDiff = connector(DashboardScreen);
