import produce from "immer";
import { IAction } from "../common";
import vol24hInitialState from "./vol24h.initial-state";
import { IVol24hState } from "./vol24h.interface";
import _ from "lodash";
import { Types } from "./vol24h.action";
import moment from "moment";
import {
  getNumberStepsOfTimes,
  initStatisticDataList,
} from "../../modules/times";

export const vol24hReducer = produce((state: IVol24hState, action: IAction) => {
  const data = action.data;
  const message = action.message;
  switch (action.type) {
    case Types.setState: {
      const updatedState = data.state;
      for (const key in updatedState) {
        _.set(state, key, updatedState[key]);
      }
      return state;
    }
    case Types.resetState: {
      const stateName: keyof IVol24hState = data.stateName;
      _.set(state, stateName, vol24hInitialState[stateName]);
      return state;
    }
    case Types.cleanAll: {
      return vol24hInitialState;
    }

    case Types.getVol24hListRequest: {
      _.set(state, "isFetching", true);
      _.set(state, "message", "");
      return state;
    }
    case Types.getVol24hListSuccess: {
      _.set(state, "isFetching", false);
      let minVolume = 0;

      const dataList = data.data.map((item, index) => {
        // item.volume = item.volume/1000000;
        let change = index ? item.volume - data.data[index - 1].volume : 0;
        let percentChange = index
          ? ((change * 100) / data.data[index - 1].volume).toFixed(2)
          : "";
        if (!minVolume) {
          minVolume = item.volume;
        }
        if (minVolume > item.volume) {
          minVolume = item.volume;
        }
        return {
          ...item,
          fixedVolume: item.volume.toLocaleString("vi-VN"),
          timeFormat: moment(item.timestamps).format(
            ["2d", "7d", "30d"].includes(state.query.range_time)
              ? "DD/MM HH:mm"
              : "3M" === state.query.range_time
              ? "DD/MM"
              : "HH:mm"
          ),
          change,
          percentChange,
        };
      });
      _.set(state, "dataList", dataList);
      _.set(state, "minVolume", minVolume);
      return state;
      // _.set(state)
    }
    case Types.getVol24hListFailure: {
      _.set(state, "isFetching", false);
      _.set(state, "message", message);
      return state;
    }

    case Types.getMultipleStatisticRequest: {
      _.set(state, "isFetching", true);
      _.set(state, "message", "");
      return state;
    }
    case Types.getMultipleStatisticSuccess: {
      _.set(state, "isFetching", false);
      const meta = data.meta;
      const key = data.key || "";
      let index = 0;
      let symbol = data.data[0]?.symbol;
      const labels = {};
      const timeFormat = ["2d", "7d", "30d"].includes(state.query.range_time)
        ? "DD/MM HH:mm"
        : "3M" === state.query.range_time
        ? "DD/MM"
        : "HH:mm";
      // let minVol = 0;
      let dataList: any[] = [];
      const multipleData = {};
      data.data.forEach((item, iIndex) => {
        if (symbol !== item.symbol) {
          symbol = item.symbol;
          index = 0;
        }

        if (!index) {
          const t1 = item.timestamps;
          const t2 = meta.minTimestamps;

          if (t1 > t2) {
            const steps = getNumberStepsOfTimes(t1, t2, meta.step);
            const additionalData = initStatisticDataList({
              stepCount: steps,
              step: meta.step,
              minTimestamps: meta.minTimestamps,
              symbol: item.symbol,
              timeFormat,
            });
            dataList = dataList.concat(additionalData);
          }
        }
        let change = index ? item.volume - data.data[iIndex - 1].volume : 0;
        let percentChange = index
          ? Number(((change * 100) / data.data[iIndex - 1].volume).toFixed(2))
          : 0;
        index++;
        const formatingTime = moment(item.timestamps)
          //@ts-ignore
          .startOf(getStartOf(meta.step))
          .format(timeFormat);
        if (!labels[formatingTime]) {
          labels[formatingTime] = 1;
        }
        const itemData = {
          ...item,
          fixedVolume: item.volume.toLocaleString("vi-VN"),
          timeFormat: formatingTime,
          change,
          percentChange,
        };
        if (!multipleData[item.symbol]) {
          multipleData[item.symbol] = [];
        }
        multipleData[item.symbol].push(itemData);
      });
      state.multipleData[key] = multipleData;
      _.set(state, "multipleDataListLabels", Object.keys(labels));
      _.set(state, "multipleData", state.multipleData);
      return state;
    }
    case Types.getMultipleStatisticFailure: {
      _.set(state, "isFetching", false);
      _.set(state, "message", message);
      return state;
    }

    case Types.getTopDiffRequest: {
      _.set(state, "isFetching", true);
      _.set(state, "message", "");
      return state;
    }
    case Types.getTopDiffSuccess: {
      _.set(state, "isFetching", false);
      const topDiff = data.data;
      _.set(state, "topDiff", topDiff);
      return state;
      // _.set(state)
    }
    case Types.getTopDiffFailure: {
      _.set(state, "isFetching", false);
      _.set(state, "message", message);
      return state;
    }

    case Types.getFibonacciRequest: {
      _.set(state, "isFetching", true);
      _.set(state, "message", "");
      return state;
    }
    case Types.getFibonacciSuccess: {
      _.set(state, "isFetching", false);
      const fibonacci = data.data;
      _.set(state, "fibonacci", fibonacci);
      return state;
      // _.set(state)
    }
    case Types.getFibonacciFailure: {
      _.set(state, "isFetching", false);
      _.set(state, "message", message);
      return state;
    }


    default: {
      return state;
    }
  }
}, vol24hInitialState);

const getStartOf = (step: string) => {
  let startOf = "hour";
  if (step === "1h") {
    startOf = "hour";
  }
  if (step === "5m") {
    startOf = "minute";
  }
  if (step === "1d") {
    startOf = "day";
  }
  return startOf;
};
