import AppApi from "../../modules/api";
import { IGetExchange } from "./exchange.interface";

export const exchangeApi = {
  getExchanges: (query: IGetExchange) => {
    const queryString = AppApi.formatQuery(query);
    const api = "/api/exchange" + queryString;
    return AppApi.fetchData(api);
  },
};
