import { IFileState } from "./file.interface";

export const fileInitialState: IFileState = {
  isFetching: false,
  message: "",
  errorTitle: "",
  files: [],
  category: "",
  type: "",
};
