import { ILogin } from "./auth.interface";
import AppApi from "../../modules/api";

class AuthApi {
  login(data: ILogin) {
    const api = "/api/auth/login";
    const body = { username: data.email, password: data.password };
    return AppApi.fetchData(api, "POST", JSON.stringify(body));
  }
  checkAuthorized() {
    const api = "/api/auth/check-authorized";
    return AppApi.fetchData(api, "POST");
  }
  resetPassword(email: string) {
    const api = "/api/auth/reset-password";
    return AppApi.fetchData(api, "POST", JSON.stringify({ email }));
  }
}

export const authApi = new AuthApi();
