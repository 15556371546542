import React, { useEffect, useState } from "react";
import { Menu, Layout } from "antd";
import { Link } from "react-router-dom";
import { RouteConfig } from "../config/routes";
import logo from "../assets/images/logo/logo.jpg";
import { IRootState } from "../redux";
import { connect, ConnectedProps } from "react-redux";
import { settingAction } from "../redux/setting";

const { Sider } = Layout;
interface PropsType {
  routes: RouteConfig[];
}
const mapStateToProps = (state: IRootState) => ({
  router: state.router,
});
const mapDispatchToProps = {
  getSettingList: settingAction.getList,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const MenuNavScreen = React.memo((props: PropsType & PropsFromRedux) => {
  const { routes, router, getSettingList } = props;
  const [collapse, setCollapse] = useState(false);
  const [key, setKey] = useState(["0"]);
  const localUrl = window.location.href;
  useEffect(() => {
    if (routes.length) {
      // const localUrl = window.location.href;
      const index = routes.findIndex((route) => localUrl.includes(route.path));
      if (index > -1) {
        setKey([index.toString()]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routes, router.location.pathname]);

  useEffect(() => {
    // getSettingList({ page: 1, pageSize: 20 });
  }, [getSettingList]);

  const onCollapse = (collapsed) => {
    setCollapse(collapsed);
  };
  const onSelect = (data: any) => {
    const { selectedKeys } = data;
    setKey(selectedKeys);
  };
  return (
    <Sider
      collapsible
      collapsed={collapse}
      onCollapse={onCollapse}
      className="side-nav"
      // style={{ backgroundColor: "#942611" }}
    >
      <div className="logo">
        <img alt="logo" width="60" src={logo} />
      </div>
      {/* <Divider /> */}
      <Menu
        theme="dark"
        // defaultSelectedKeys={["1"]}
        // defaultSelectedKeys={key}
        selectedKeys={key}
        mode="inline"
        className="side-menu"
        onSelect={onSelect}
        // style={{ backgroundColor: "#942611" }}
      >
        {routes.map((item, index) => {
          return (
            <Menu.Item key={index.toString()}>
              <Link to={item.path}>
                <span>{item.name}</span>
              </Link>
            </Menu.Item>
          );
        })}
      </Menu>
    </Sider>
  );
});

export const MenuNav = connector(MenuNavScreen);
