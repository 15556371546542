/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IRootState } from "../../redux";
import { Volume } from "../../components/Dashboard/Volume";
import { Button, Col, Radio } from "antd";
import { symbolActions } from "../../redux/symbol";
import { vol24hActions } from "../../redux/vol24h";
import moment from "moment";
import { FaPlus } from "react-icons/fa";
import { cacheToStorage, loadFromStorage } from "../../modules";

const mapStateToProps = (state: IRootState) => ({
  vol24h: state.vol24h,
});

const mapDispatchToProps = {
  getSymbolList: symbolActions.getList,
  set24hState: vol24hActions.setState,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const rangeTimeList = [
  { key: "1h", value: 1 },
  { key: "2h", value: 2 },
  { key: "4h", value: 4 },
  { key: "6h", value: 6 },
  { key: "12h", value: 12 },
  { key: "24h", value: 24 },
  { key: "2d", value: 48 },
  { key: "7d", value: 24 * 7 },
  { key: "30d", value: 24 * 30 },
  { key: "3M", value: 24 * 30 * 3 },
];
const DashboardScreen = React.memo((props: PropsFromRedux) => {
  const {
    getSymbolList,
    set24hState,
    vol24h,
  } = props;
  const {
    range_time,
  } = vol24h.query;
  const { chartIds } = vol24h
  useEffect(() => {
    getSymbolList({ status: "active" });
    //@ts-ignore
  }, [getSymbolList]);
  useEffect(() => {
    let currChartIds = loadFromStorage('chartIds');
    console.log("🚀 ~ file: Dashboard.tsx:53 ~ useEffect ~ currChartIds:", currChartIds)

    if (currChartIds) {
      currChartIds = JSON.parse(currChartIds);
      if (currChartIds?.length) {
        set24hState({ chartIds: currChartIds })
        return;
      }
    }

    let count = 0;
    let key = localStorage.key(count);
    let newChartIds: number[] = [];
    while (key) {
      if (!isNaN(Number(key))) {
        newChartIds.push(Number(key));
      }
      count += 1;
      key = localStorage.key(count)
    }
    if (!newChartIds.length) {
      newChartIds = [1]
    }
    else {
      newChartIds = newChartIds.sort((a, b) => a - b)
    }

    set24hState({ chartIds: newChartIds })
  }, [])
  useEffect(() => {
    cacheToStorage('chartIds', JSON.stringify(chartIds))
  }, [chartIds])
  return (
    <>
      <Col span={24}>
        <div style={{ display: "flex", justifyContent: "start" }}>
          <Radio.Group value={range_time}>
            {rangeTimeList.map((item) => {
              return (
                <Radio.Button
                  onClick={() => {
                    const toTime = Date.now();
                    const toDate = new Date(
                      moment().format("YYYY-MM-DD")
                    ).getTime();
                    const query = {
                      "query.range_time": item.key,
                      "query.to_time": toTime,
                      "query.to_date": toDate,
                    };
                    set24hState(query);
                  }}
                  key={item.key}
                  value={item.key}
                >
                  {item.key}
                </Radio.Button>
              );
            })}
          </Radio.Group>
        </div>
      </Col>
      {
        vol24h.chartIds.map((item, index) => {
          return (<Volume chartIndex={index + 1} key={item} chartId={`${item}`} />)
        })
      }
      <Button
        type="primary"
        title="Thêm chart"
        shape="circle"
        size="large"
        style={{ position: 'fixed', right: '10px', bottom: '10px' }}
        onClick={() => {
          if (chartIds.length === 20) {
            return;
          }
          const newChartIds = [...chartIds, chartIds[chartIds.length - 1] + 1]
          set24hState({ chartIds: newChartIds })
        }
        }
      >
        <FaPlus />
      </Button>
    </>
  );
});

export const Dashboard = connector(DashboardScreen);
