import React, { useEffect } from "react";
import _ from "lodash";
import { Form, Input, Button, Spin } from "antd";
import { connect, ConnectedProps } from "react-redux";
import { push } from "connected-react-router";
import { routeName } from "../../config/route-name";
import notification from "../../modules/notification";
import { adminAction } from "../../redux/admin";
import authAction from "../../redux/auth/auth.action";
import { IRootState } from "../../redux";

const mapStateToProps = (state: IRootState) => ({
  email: state.auth.email,
  password: state.auth.password,
  errors: state.auth.errors,
  isFetching: state.auth.isFetching,
  message: state.auth.message,
});
const mapDispatchToProps = {
  changeFields: adminAction.changeFields,
  goToPage: push,
  login: authAction.login,
  setAuthState: authAction.setState,
  setAdminState: adminAction.setState,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const LoginScreen = React.memo((props: PropsFromRedux) => {
  const {
    email,
    password,
    errors,
    isFetching,
    message,
    setAuthState,
    login,
    goToPage,
    setAdminState,
  } = props;

  useEffect(() => {
    if (message) {
      notification.showError(message);
      setAuthState({ message: "" });
    }
  }, [message, setAuthState]);

  const handleSubmit = () => {
    // e.preventDefault();
    let newErrors = { ...errors };
    if (!email || email === "") {
      newErrors.email = "Không được để trống";
    }
    if (!password || password === "") {
      newErrors.password = "Không được để trống";
    }
    if (_.isEmpty(newErrors)) {
      login({ email, password }).then((res) => {
        if (res) {
          goToPage(routeName.dashboard);
          setAdminState({ _id: res });
        }
        // goToPage(routeName.dashboard);
      });
      // props.changeFields({ _id: 'adfasdf' });
    } else {
      setAuthState({ newErrors });
    }
  };

  const onInputChange = (key, value) => {
    if (errors[key]) {
      delete errors[key];
    }
    setAuthState({ [key]: value, errors: { ...errors } });
  };

  return (
    <Spin spinning={isFetching}>
      <Form onFinish={handleSubmit} className="login-form">
        <Form.Item>
          <Input
            // prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
            name="email"
            onChange={(event) => onInputChange("email", event.target.value)}
            placeholder="Email"
            value={email}
          />
          {errors.email && <span style={{ color: "red" }}>{errors.email}</span>}
        </Form.Item>
        <Form.Item>
          <Input
            // prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
            type="password"
            name="password"
            onChange={(event) => onInputChange("password", event.target.value)}
            placeholder="Password"
            value={password}
          />
          {errors.password && (
            <span style={{ color: "red" }}>{errors.password}</span>
          )}
        </Form.Item>
        <div style={{ textAlign: "center" }}>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Log in
          </Button>
        </div>
        <div style={{ textAlign: "right" }}>
          <span
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => props.goToPage(routeName.forgotPassword)}
          >
            <i>Quên mật khẩu</i>
          </span>
        </div>
      </Form>
    </Spin>
  );
});

export const Login = connector(LoginScreen);
