import Joi from "joi";

export const settingValidation = {
  update: Joi.object().keys({
    setting_id: Joi.string()
      .regex(/[a-zA-Z0-9]{24}$/, { name: "object id" })
      .required(),
    valueList: Joi.array()
      .items(Joi.string())
      .when("title", {
        is: Joi.exist(),
        then: Joi.forbidden(),
      }),
    title: Joi.string(),
    content: Joi.string().when("title", {
      is: Joi.exist(),
      then: Joi.required(),
      otherwise: Joi.forbidden(),
    }),
  }),
};
