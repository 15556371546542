import { notification } from 'antd';

export default {
  /**
   *
   */
  showError: (message: string, description?: string) => {
    notification.error({
      message,
      description,
    });
  },
  showSuccess: (message: string, description?: string) => {
    notification.success({
      message,
      description,
    });
  },
};
