import React, { useEffect } from "react";
import { Layout } from "antd";
import { Switch, Route, Redirect } from "react-router-dom";
import { RouteConfig, subRoutes } from "../config/routes";
import { Header as CustomHeader, MenuNav } from "../components";
import "./style.css";
import { connect, ConnectedProps } from "react-redux";
import { IRootState } from "../redux";
import authAction from "../redux/auth/auth.action";
import { UnActivated } from "./UnActivated";
import { vol24hActions } from "../redux/vol24h";
import { exchangeActions } from "../redux/exchange";
import dayjs from "dayjs";
import { ONE_MINUTE_IN_MIL_SECONDS } from "../modules/times";

const { Content, Footer } = Layout;
interface PropsType {
  routes: RouteConfig[];
}

const mapStateToProps = (state: IRootState) => ({
  admin: state.admin.info,
  volChangeList: state.vol24h.volChangeList,
});
const mapDispatchToProps = {
  checkAuthorized: authAction.checkAuthorized,
  setVol24hState: vol24hActions.setState,
  setExchangeState: exchangeActions.setState,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const Main = React.memo((props: PropsType & PropsFromRedux) => {
  const {
    routes: propsRoutes,
    volChangeList,
    admin,
    setVol24hState,
    setExchangeState,
  } = props;
  const routes = subRoutes.concat(propsRoutes);

  useEffect(() => {
    setVol24hState({ "query.to_time": Date.now() });
    setTimeout(() => {
      setExchangeState({
        "query.created_to":
          new Date(
            dayjs()
              .startOf("hours")
              .toISOString()
          ).getTime() +
          Math.floor(new Date().getMinutes() / 5) *
            5 *
            ONE_MINUTE_IN_MIL_SECONDS,
      });
    }, 15000);
  }, [volChangeList, setVol24hState, setExchangeState]);

  return (
    // <Layout>
    <Layout
      className="custom-layout"
      style={{ minHeight: "100vh", backgroundColor: "#232a3b" }}
    >
      {admin.status === "active" ? (
        <>
          <MenuNav routes={propsRoutes} />
          <Layout className="main-layout" style={{ position: "relative" }}>
            <CustomHeader />
            <Content className="main-content">
              <Switch>
                {routes.map((item) => (
                  <Route
                    key={item.path}
                    path={item.path}
                    component={item.component}
                  />
                ))}

                {propsRoutes.length > 0 ? (
                  <Redirect to={propsRoutes[0].path} />
                ) : null}
              </Switch>
            </Content>
            <Footer style={{ textAlign: "center", margin: "0" }}>
              Volcoin24h @2023
            </Footer>
          </Layout>
        </>
      ) : (
        <Layout className="main-layout">
          <CustomHeader />
          <Content className="main-content">
            <UnActivated />
          </Content>
        </Layout>
      )}
    </Layout>
  );
});
export const MainLayout = connector(Main);
