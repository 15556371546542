import produce from "immer";
import { IAction } from "../common";
import symbolInitialState from "./symbol.initial-state";
import { ISymbolState } from "./symbol.interface";
import _ from "lodash";
import { Types } from "./symbol.action";

export const symbolReducer = produce((state: ISymbolState, action: IAction) => {
  const data = action.data;
  const message = action.message;
  switch (action.type) {
    case Types.setState: {
      const updatedState = data.state;
      for (const key in updatedState) {
        _.set(state, key, updatedState[key]);
      }
      return state;
    }
    case Types.resetState: {
      const stateName: keyof ISymbolState = data.stateName;
      _.set(state, stateName, symbolInitialState[stateName]);
      return state;
    }
    case Types.cleanAll: {
      return symbolInitialState;
    }

    case Types.getSymbolListRequest: {
      _.set(state, "isFetching", true);
      _.set(state, "message", "");
      return state;
    }
    case Types.getSymbolListSuccess: {
      _.set(state, "isFetching", false);
      _.set(state, "symbolList", data.data);
      return state;
      // _.set(state)
    }
    case Types.getSymbolListFailure: {
      _.set(state, "isFetching", false);
      _.set(state, "message", message);
      return state;
    }

    case Types.createRequest: {
      _.set(state, "isFetching", true);
      _.set(state, "message", "");
      return state;
    }
    case Types.createSuccess: {
      _.set(state, "isFetching", false);
      _.set(state, "symbolList", state.symbolList.push (data.data));
      return state;
      // _.set(state)
    }
    case Types.createFailure: {
      _.set(state, "isFetching", false);
      _.set(state, "message", message);
      return state;
    }

    case Types.updateRequest: {
      _.set(state, "isFetching", true);
      _.set(state, "message", "");
      return state;
    }
    case Types.updateSuccess: {
      const index = state.symbolList.findIndex(sym => sym._id === data.data._id);
      state.symbolList[index] = {...state.symbolList[index], ...data.data};
      _.set(state, "isFetching", false);
      _.set(state, "symbolList", state.symbolList);
      return state;
      // _.set(state)
    }
    case Types.updateFailure: {
      _.set(state, "isFetching", false);
      _.set(state, "message", message);
      return state;
    }

    case Types.getAllRequest: {
      _.set(state, "isFetching", true);
      _.set(state, "message", "");
      return state;
    }
    case Types.getAllSuccess: {
      const currentList = state.symbolList;
      const symbolList = data.data;
      const result = symbolList.filter(item => !currentList.some(cur => cur.symbol === item));
      _.set(state, 'allSymbolList', result);
      _.set(state, "isFetching", false);
      return state;
      // _.set(state)
    }
    case Types.getAllFailure: {
      _.set(state, "isFetching", false);
      _.set(state, "message", message);
      return state;
    }

    default: {
      return state;
    }
  }
}, symbolInitialState);
