import {combineReducers} from "redux";
import {connectRouter} from "connected-react-router";
import {history} from "../config/history";
import {authReducer as auth} from "./auth";
import {adminReducer as admin} from "./admin";
import {fileReducer as file} from "./file";
import {settingReducer as setting} from "./setting";
import {symbolReducer as symbol} from "./symbol";
import {vol24hReducer as vol24h} from "./vol24h";
import {exchangeReducer as exchange} from "./exchange";
import {openInterestReducer as openInterest} from "./open-interest";

export const reducer = combineReducers({
  router: connectRouter(history),
  auth,
  admin,
  file,
  setting,
  symbol,
  vol24h,
  exchange,
  openInterest
});

export type IRootState = ReturnType<typeof reducer>;
