import { Radio } from "antd";
import React from "react";

interface ITimeSelectorProps {
  timeList?: { key: string; value: number }[];
  value?: string;
  onChange?: (item: { key: string; value: number }) => void;
}

const defaultTimeList = [
  { key: "1h", value: 1 },
  { key: "2h", value: 2 },
  { key: "4h", value: 4 },
  { key: "6h", value: 6 },
  { key: "12h", value: 12 },
  { key: "24h", value: 24 },
  { key: "2d", value: 48 },
  { key: "7d", value: 24 * 7 },
  { key: "30d", value: 24 * 30 },
  { key: "3M", value: 24 * 30 * 3 },
];

export const TimeSelector = (props: ITimeSelectorProps) => {
  const { value, timeList, onChange } = props;
  const rangeTimeList = timeList || defaultTimeList;
  return (
    <Radio.Group value={value}>
      {rangeTimeList.map((item) => {
        return (
          <Radio.Button
            onClick={() => {
              if (onChange) {
                onChange(item);
              }
            }}
            key={item.key}
            value={item.key}
          >
            {item.key}
          </Radio.Button>
        );
      })}
    </Radio.Group>
  );
};
