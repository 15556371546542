import { removeAdmin } from "../../modules";
import { adminAction } from "../admin";

export const cleanAllState = (dispatch) => {
  removeAdmin();
  dispatch(adminAction.cleanAll());
  // dispatch()
};

export const updateAdminInfo = (data: any = {}) => (dispatch) => {
  dispatch(adminAction.setInfo(data));
};
