import {
  IAdminCreate,
  IAdminQuery,
  IAdminUpdate,
  IChangeAdminStatus,
  IAdminChangePassword,
} from "./admin.interface";
import AppApi from "../../modules/api";

export const adminApi = {
  getList: (query: IAdminQuery) => {
    const queryString = AppApi.formatQuery(query);
    const api = "/api/admin/admin" + queryString;
    return AppApi.fetchData(api);
  },
  getById: (id: string) => {
    const api = "/api/admin/admin/" + id;
    return AppApi.fetchData(api);
  },
  updatePersonalInfo: (doc: IAdminUpdate) => {
    const api = "/api/admin/admin/update-info";
    return AppApi.fetchData(api, "PUT", JSON.stringify(doc));
  },
  changePassword: (doc: IAdminChangePassword) => {
    const api = "/api/user/change-pass";
    return AppApi.fetchData(api, "PUT", JSON.stringify(doc));
  },
  create: (doc: IAdminCreate) => {
    const api = "/api/admin/admin/create-new";
    return AppApi.fetchData(api, "POST", JSON.stringify(doc));
  },
  changeStatus: (doc: IChangeAdminStatus) => {
    const api = "/api/admin/admin/change-status";
    return AppApi.fetchData(api, "PUT", JSON.stringify(doc));
  },
};
