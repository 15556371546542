import { Button, Col, Modal, Result, Row } from "antd";
import React from "react";

interface IConfirmActionProps {
  title: any;
  visible: boolean;
  onChange?: (key: "approved" | "canceled") => void;
}

export const ConfirmAction = React.memo((props: IConfirmActionProps) => {
  const { title, visible } = props;

  const onChange = (key) => {
    if (props.onChange) {
      props.onChange(key);
    }
  };

  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={() => onChange("canceled")}
    >
      {/* <h4 className="text-center">{title}</h4> */}
      <Result status="warning" title={title} />
      <Row>
        <Col span={12} style={{ cursor: "pointer" }} className="text-center">
          <Button
            onClick={() => onChange("canceled")}
            type="dashed"
            style={{ width: "150px" }}
          >
            Hủy
          </Button>
        </Col>
        <Col span={12} style={{ cursor: "pointer" }} className="text-center">
          <Button
            onClick={() => onChange("approved")}
            type="primary"
            style={{ width: "150px" }}
          >
            Đồng ý
          </Button>
        </Col>
      </Row>
    </Modal>
  );
});
