import { createAction } from "../common";
import api, { ResponseInterface } from "../../modules/api";
import { saveAdmin, removeAdmin, loadAdmin } from "../../modules/storage";
import { adminAction } from "../admin";
import { authApi } from "./auth.api";
import { cleanAllState, updateAdminInfo } from "./auth.handler";

export const Types = {
  changeFields: "auth.change-fields",

  loginRequest: "auth.login-request",
  loginSuccess: "auth.login-success",
  loginFailure: "auth.login-failure",

  resetPasswordRequest: "auth.resetPassword-request",
  resetPasswordSuccess: "auth.resetPassword-success",
  resetPasswordFailure: "auth.resetPassword-failure",

  setState: "auth.set-state",
  cleanAll: "auth.clean-all",
};

const changeFields = (object: any = {}) =>
  createAction(Types.changeFields, object);

const login = ({ email, password }: { email: string; password: string }) => (
  dispatch: any
) => {
  dispatch(createAction(Types.loginRequest));
  return authApi
    .login({ email, password })
    .then((res: ResponseInterface<any>) => {
      if (res.success) {
        saveAdmin({
          ...res.data,
          // token: res.data.token,
        });
        api.setToken(res.data.token);
        dispatch(adminAction.setInfo(res.data));
        dispatch(createAction(Types.loginSuccess, res.data));
        return res.data._id;
      } else {
        // let message = utils.getMessageError(res.errors);
        dispatch(createAction(Types.loginFailure, {}, res.message));
        return false;
      }
    });
};

const resetPassword = (email: string) => (dispatch) => {
  dispatch(createAction(Types.resetPasswordRequest));
  return authApi.resetPassword(email).then((res: ResponseInterface<any>) => {
    if (res.success) {
      dispatch(createAction(Types.resetPasswordSuccess, res.data));
      return true;
    } else {
      dispatch(createAction(Types.resetPasswordFailure, {}, res.message));
      return false;
    }
  });
};

const logout = () => (dispatch) => {
  removeAdmin();
  api.setToken("");

  // resetState();
  dispatch(cleanAll());
  cleanAllState(dispatch);
};
const setState = (state: any) => createAction(Types.setState, { state });
const cleanAll = () => createAction(Types.cleanAll);
const checkAuthorized = () => (dispatch) => {
  authApi.checkAuthorized().then((res) => {
    if (!res.success) {
      if (res.statusCode === 401) {
        cleanAllState(dispatch);
      }
    } else {
      const admin = loadAdmin();
      const saveData: any = { ...admin, ...res.data };
      saveAdmin(saveData);
      updateAdminInfo(res.data)(dispatch);
    }
  });
};

const resetAllState = () => (dispatch: any) => {
  cleanAllState(dispatch);
};

export default {
  changeFields,
  login,
  logout,
  setState,
  cleanAll,
  checkAuthorized,
  resetAllState,
  resetPassword,
};
